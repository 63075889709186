// Requires:
// HTML5:          <!DOCTYPE html>
// viewport meta:  <meta name="viewport" content="initial-scale=1.0, user-scalable=no" />
// GMaps V3 API:   http://maps.google.com/maps/api/js?sensor=set_to_true_or_false

/// GMaps2 API:     http://maps.google.com/maps?file=api&v=2


// Usage:
//  PWGMap = new PWGMap_impl(STATIC_URL);
//  PWGMap.<page_name>Initialize(page_specific_parameters);

// e.g.
//  PWGMap = new PWGMap_impl(STATIC_URL);
//  PWGMap.routerInitialize(routerInitialValues);
//where...
//  routerInitialValues = {
//     is8km: true,
//     boatPolarType: 'advanced',
//     optimiseFor: 'comfort',
//     routerOutput: { ... },
//     waypoints: [ ... ],
// };

import * as pwMap from './map.js';
import {html, nothing, render} from "lit";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {styleMap} from "lit/directives/style-map.js";
import {isOffshoreApp, isSmartPhone} from '../platform.js';
import PWRoutingGraphs from "./components/routing-graphs.js";
import {
    PWRoutingAtmosphereTables,
    PWRoutingAtmosphereUnits,
    PWRoutingCurrentTables,
    PWRoutingCurrentUnits,
    PWRoutingDeparturePlanningTables,
    PWRoutingRouteTables,
    PWRoutingSummaryTables,
    PWRoutingSummaryUnits,
    PWRoutingSummaryComfortSettings,
    PWRoutingWindTables,
    PWRoutingWindNotes,
    PWRoutingWindUnits,
    ROUTE_COLOURS
} from "./components/routing-tables.js";
import PWRoutingUpgradeOverlay from './components/routing-upgrade-overlay.js';
import PWRoutingWavesSelector from './components/routing-waves-selector.js';
import PWRoutingWavesTables from './components/routing-waves-tables.js';
import PWRoutingWavesUnitsTable from './components/routing-waves-units-table.js';
import {BoundaryEditor} from './components/routing-boundary-editor.js';
import {format1dp, formatRounded, formatTimestamp, isset, pad2, ROUTING_INVALID_VALUE, shouldShowFuelConsumption} from './routing-utils.js';
import {addWarningsToRoute, warningItemsFromRoute} from './routingWeatherWarnings.js';
import {convert, GET_FIELD_UNIT} from "./convertable.js";
import {units, convertAndFormat} from './convertable-units.js';
import {preference_units, getPreferenceUnitId} from "./preference-units.js";
import {routing_units, getUserNode} from './routing-units.js';
import MapRuler from "./map-ruler.js";
import AtlasTooltipContent from './components/atlas-tooltip-content.js';
import "../tooltip.js";
import {PWRoutingPowerSettings} from './components/routing-power-settings.js';

export {pad2};

const
    gettext = window.gettext,
    interpolate = window.interpolate;

// FIXME dynamically import modules that define custom elements when we are about to actually need them?
customElements.define('pw-routing-upgrade-overlay', PWRoutingUpgradeOverlay);
customElements.define('pw-routing-waves-selector', PWRoutingWavesSelector);
customElements.define('pw-routing-waves-units-table', PWRoutingWavesUnitsTable);
customElements.define('pw-routing-waves-tables', PWRoutingWavesTables);
customElements.define('pw-routing-route-tables', PWRoutingRouteTables);
customElements.define('pw-routing-summary-units', PWRoutingSummaryUnits);
customElements.define('pw-routing-summary-tables', PWRoutingSummaryTables);
customElements.define('pw-routing-summary-comfort-settings', PWRoutingSummaryComfortSettings);
customElements.define('pw-routing-wind-tables', PWRoutingWindTables);
customElements.define('pw-routing-wind-notes', PWRoutingWindNotes);
customElements.define('pw-routing-wind-units', PWRoutingWindUnits);
customElements.define('pw-routing-current-units', PWRoutingCurrentUnits);
customElements.define('pw-routing-current-tables', PWRoutingCurrentTables);
customElements.define('pw-routing-atmosphere-units', PWRoutingAtmosphereUnits);
customElements.define('pw-routing-atmosphere-tables', PWRoutingAtmosphereTables);
customElements.define('pw-routing-departure-planning-tables', PWRoutingDeparturePlanningTables);
customElements.define('pw-routing-graphs', PWRoutingGraphs);
customElements.define('atlas-tooltip-content', AtlasTooltipContent);
customElements.define('pw-routing-power-settings', PWRoutingPowerSettings);


const _PWGMap_initListeners = $j.Callbacks('unique once memory');

export function PWGMap_addInitListener(callback) {
    function wrapper() {
        try {
            return callback();
        } catch (e) {
            window.Sentry?.captureException(e);
            console.error(e.message, e.line, e.stack);
        }
    }

    _PWGMap_initListeners.add(wrapper);
}

function initTooltipFeatures() {
    // NOTE: We add the listener using addListener on the _atlasMap so it gets called after
    //       the tooltipPin is set - this allows us to determine what the pinned property should
    //       be on the atlas-tooltip-content component
    PWGMap.ruler = new MapRuler(PWGMap.map1 || PWGMap[0]);
    $j('body')
        .on('initialise-ruler', (event) => {
            $j('pw-map-ruler-button').prop({'showEndPrompt': true})
            let {p} = event.detail;
            if (PWGMap.ruler) {
                $j('pw-map-ruler-button').prop('open', true);
                PWGMap.ruler.enable(p)
                if (!isSmartPhone) {
                    $j('#show-hide-table-buttons').hide();
                    $j('.observations-toggle-container-container').hide();
                    $j('.observations-zoom-message-container-container').hide();
                }
            } else {
                console.log("Ruler has not been initialised");
            }
        });
}

function setRoutingWavesFilter(value) {
    $j('.routing-waves-filter').attr('data-routing-waves-filter', value);
    $j('pw-routing-waves-selector').attr('value', value);
    $j('pw-routing-waves-tables,pw-routing-graphs').attr('waveFilter', value);
}

window.document.body.addEventListener('routing-waves-selector-changed', (e) => {
    setRoutingWavesFilter(e.detail);
});

window.document.body.addEventListener('routing-select-route-node', (e)=> {
    window.PWGMap.showWindSampleOnMap(e.detail);
});


const bad_timezone = '[object HTMLSelectElement]';

export function checkTimezone(name) {
    if (name === bad_timezone) {
        throw new Error("Given timezone is bad value");
    }
    $j('[name=timezone]').each((i, el) => {
        if ($j(el).val() === bad_timezone) {
            // It would be nice to not break stuff and keep going somehow, but I need a traceback to diagnose this issue.
            throw new Error(`Timezone control ${el} has bad value.`);
        }
    });
}

export const PWGMap_geocoder = pwMap.geocoder();

export function getSpeedUnitId() {
    console.debug('getSpeedUnitId is deprecated');
    return getPreferenceUnitId('Units_WindSpeed');
}

export function formatCoords() {
    // FIXME don't export this just use the pwMap func elsewhere?
    return pwMap.utils.formatCoords.apply(null, arguments);
}

export function convertKnotsToUser(value, dp) {
    console.debug('convertKnotsToUser is deprecated');
    value = convert(value, units.knots, preference_units.wind_speed);
    if (dp !== undefined) {
        value = (value * 1).toFixed(dp);
    }
    return value;
}

export function formatTimezoneShort(utcOffset) {
    const h = Math.floor(Math.abs(utcOffset));
    const m = Math.floor(Math.abs(utcOffset) * 60) % 60;
    return pgettext("UTC timezone", "GMT") +
        (utcOffset === 0
            ? ''
            : (utcOffset >= 0 ? '+' : '-') + h +
            (m > 0 ? ':' + (m < 10 ? '0' : '') + m
                : ''));
}

export function formatTimestampLong(timestamp, utcOffset) {
    if (timestamp === null || isNaN(timestamp)) {
        if (typeof timestamp === 'string') {
            return timestamp;
        } else {
            return '';
        }
    }
    if (!timestamp) {
        return '';
    }
    return formatTimestamp(timestamp + utcOffset * 3600, true) + ' (' + formatTimezoneShort(utcOffset) + ')';
    //var date = new Date();
    //date.setTime(timestamp*1000);
    //return date.toUTCString();
}

function formatWeatherTimestamp(timestamp, utcOffset, showYear, showOffset) {
    if (timestamp === null || isNaN(timestamp)) {
        if (typeof timestamp === 'string') {
            return timestamp;
        } else {
            return '';
        }
    }
    if (!timestamp) {
        return '';
    }
    if (typeof showOffset == 'undefined') showOffset = true;

    const date = new Date();
    date.setTime((timestamp + utcOffset * 3600) * 1000);

    let result = '';
    result += window.GET_DAY_NAME_3(date.getUTCDay()) + ' ' + date.getUTCDate() + '/' + window.GET_MONTH_NAME_3(date.getUTCMonth());
    if (showYear) {
        result += ' ' + date.getUTCFullYear() + ',';
    }
    result += ' ' + pad2(date.getUTCHours()) + ":" + pad2(date.getUTCMinutes());
    if (showOffset) {
        result += ' ' + formatTimezoneShort(utcOffset);
    }
    return result;
}

function floatToCoord(f, positiveSuffix, negativeSuffix) {
    let suffix = positiveSuffix;
    if (f < 0) {
        f = -f;
        suffix = negativeSuffix;
    }
    const deg = Math.floor(f);
    let min = '' + (Math.round((60 * (f - deg)) * 1000.0) / 1000.0);
    if (min * 1 < 10) {
        min = '0' + min;
    }
    if (min.length === 2) {
        min += '.000';
    }
    while (min.length < 6) {
        min += '0';
    }
    return deg + 'º ' + min + suffix;
}

export function formatLatitude(lat) {
    return lat !== '' ? floatToCoord(lat, ' ' + pgettext('North, 1 letter', 'N'), ' ' + pgettext('South, 1 letter', 'S')) : '';
}

export function formatLongitude(lon) {
    return lon !== '' ? floatToCoord(lon, ' ' + pgettext('East, 1 letter', 'E'), ' ' + pgettext('West, 1 letter', 'W')) : '';
}

function formatTwa(twa) {
    if (typeof twa == 'undefined' || twa === null || twa === '') {
        return twa;
    }
    twa = twa * 1;
    if (twa < 0) {
        twa = "<span style='color:red'>P</span>" + formatRounded(-twa, 0);
    } else if (twa > 0) {
        twa = "<span style='color:green'>S</span>" + formatRounded(twa, 0);
    } else {
        twa = '0';
    }
    return twa;
}

export function PWGMap_impl(options) {
    const GLOBAL_text = {
        GMT: pgettext("UTC timezone", "GMT"),
    };

    this.map1 = pwMap.map();
    this.map2 = pwMap.map();

    // map call forwarding
    this._forward = function (target, targetFunctionName, functionName) {
        this[functionName || targetFunctionName] = function () {
            return target[targetFunctionName].apply(target, arguments);
        };
    };
    this._forward(this.map1, 'isValid', 'hasMap');
    this._forward(this.map1, 'requestLayout');
    this._forward(this.map1, 'getBounds');
    this._forward(this.map1, 'setBounds');
    this._forward(this.map1, 'setMaxBounds');
    this._forward(this.map1, 'getBoundsSpan');
    this._forward(this.map1, 'getZoom');
    this._forward(this.map1, 'setZoom');
    this._forward(this.map1, 'enableZoom');
    this._forward(this.map1, 'disableZoom');
    this._forward(this.map1, 'getCentre');
    this._forward(this.map1, 'setCentre');
    this._forward(this.map1, 'closePopup');

    // Try to keep items on map1 contained in a special layer
    this.map1BaseLayerGroup = pwMap.layerGroup();
    this.map1ItemLayerGroup = pwMap.layerGroup();

    this._groupForZIndex = function (zIndex) {
        if (zIndex && zIndex < pwMap.zLevels.raceBoundary + 100) {
            return this.map1BaseLayerGroup;
        }
        return this.map1ItemLayerGroup;
    };
    this.addItem = function (item) {
        const group = this._groupForZIndex(item.zIndex);
        return pwMap.LayerGroup.addItem(group, item);
    };
    this.containsItem = function (item) {
        const group = this._groupForZIndex(item.zIndex);
        return pwMap.LayerGroup.containsItem(group, item);
    };
    this.removeItem = function (item) {
        const group = this._groupForZIndex(item.zIndex);
        return pwMap.LayerGroup.removeItem(group, item);
    };

    this._forward(this.map2, 'isValid', 'hasMap2');
    this._forward(this.map2, 'requestLayout', 'requestLayout2');
    this._forward(this.map2, 'getBounds', 'getBounds2');
    this._forward(this.map2, 'setBounds', 'setBounds2');
    this._forward(this.map2, 'setMaxBounds', 'setMaxBounds2');
    this._forward(this.map2, 'getBoundsSpan', 'getBoundsSpan2');
    this._forward(this.map2, 'enableZoom', 'enableZoom2');
    this._forward(this.map2, 'disableZoom', 'disableZoom2');
    this._forward(this.map2, 'getZoom', 'getZoom2');
    this._forward(this.map2, 'setZoom', 'setZoom2');
    this._forward(this.map2, 'getCentre', 'getCentre2');
    this._forward(this.map2, 'setCentre', 'setCentre2');
    this._forward(this.map2, 'addItem', 'addItem2');
    this._forward(this.map2, 'containsItem', 'containsItem2');
    this._forward(this.map2, 'removeItem', 'removeItem2');
    this._forward(this.map2, 'closePopup', 'closePopup2');

    options = options || {};
    this.utcOffset = options.utcOffset || 0;
    this.utcOffsetLabel = options.utcOffsetLabel || GLOBAL_text['GMT'] + '+00:00';
    this.weatherSource = options.weatherSource || 'predictwind';
    this.plotRouteOverlays = [];
    this.plotRouteOverlayPolylines = [];
    const minimumResolution = options.minimumResolution || 0;
    const maximumResolution = options.maximumResolution || 15;

    /// REPLACED WITH mapOpts.minZoom
    ///G_PHYSICAL_MAP.getMinimumResolution = function () { return minimumResolution; };
    ///G_NORMAL_MAP.getMinimumResolution = function () { return minimumResolution; };
    ///G_SATELLITE_MAP.getMinimumResolution = function () { return minimumResolution; };
    ///G_HYBRID_MAP.getMinimumResolution = function () { return minimumResolution; };

    const STATIC_URL = options.staticURL || '';

    // Create marker icons
    const noDataIcon = pwMap.icon({
        img: STATIC_URL + 'images/arrows/arrow_redcross.png?202103',
        width: 11,
        height: 11,
        anchorX: 5,
        anchorY: 5
    });
    const routeIcons = {};

    function makeWaypointIcon(imageUrl, className, size) {
        if (size === undefined) {
            if (window.pwMapDefaultAPI === 'atlas') {
                size = 16;
            } else {
                size = 10;
            }
        }
        return pwMap.icon({
            img: imageUrl,
            className: className,
            width: size,
            height: size,
            anchorX: size / 2,
            anchorY: size / 2
        });
    }

    function combineRouterOutput(routerOutput) {
        let paths = [];
        let weather = [];
        for (const i in routerOutput) {
            if (routerOutput[i].paths) {
                paths = paths.concat(routerOutput[i].paths);
            }
            if (routerOutput[i].weather) {
                weather = weather.concat(routerOutput[i].weather);
            }
        }
        return {paths: paths, weather: weather};
    }


    this.routerOptimiseForChanged = function (type) {
        $j('#id_optimiseFor_' + type).prop('checked', true);
        $j('#id_comfortSettings').toggle(!$j('#id_optimiseFor_time').prop("checked"));
    };

    let viewportHandlerFunction = null;
    this.viewportHandler = function () {
        if (viewportHandlerFunction) {
            viewportHandlerFunction(this.getViewport());
        }
    };
    this.setViewportHandler = function (handler) {
        viewportHandlerFunction = handler;
    };

    let dragStartedFunction = null;
    this.dragStarted = function () {
        if (dragStartedFunction) {
            dragStartedFunction(this.getViewport());
        }
    };
    this.setDragStartedFunction = function (handler) {
        dragStartedFunction = handler;
    };

    let dragEndedFunction = null;
    this.dragEnded = function () {
        if (dragEndedFunction) {
            dragEndedFunction(this.getViewport());
        }
    };
    this.setDragEndedFunction = function (handler) {
        dragEndedFunction = handler;
    };

    let zoomChangedFunction = null;
    this.zoomChanged = function () {
        if (zoomChangedFunction) {
            zoomChangedFunction(this.getViewport());
        }
    };
    this.setZoomChangedFunction = function (handler) {
        zoomChangedFunction = handler;
    };

    let clickHandlerFunction = null;
    this.clickHandler = function (p) {
        if (clickHandlerFunction) {
            clickHandlerFunction(p);
        }
    };

    this.setClickHandler = function (handler) {
        clickHandlerFunction = handler;
    };

    this.contains = function (latLon) {
        return pwMap.utils.boundsContains(this.getBounds(), latLon);
    };

    this.getViewport = function () {
        const centre = this.getCentre();
        return {
            lat: centre && centre.lat,
            lon: centre && centre.lon,
            zoom: this.getZoom(),
            b: this.getBounds()
        };
    };

    this.setViewport = function (viewport) {
        if (typeof viewport.zoom != 'undefined') {
            this.setZoom(viewport.zoom);
            this.setZoom2(viewport.zoom);
        }
        if (typeof viewport.lat != 'undefined' && typeof viewport.lon != 'undefined') {
            if (viewport.lat * 1 >= -85 && viewport.lat * 1 <= 85) {
                this.setCentre(viewport);
                this.setCentre2(viewport);
            } else {
                const defaultWindBounds = {lat: 0, lon: 0, zoom: 2};
                this.setBounds(defaultWindBounds);
                this.setBounds2(defaultWindBounds);
            }
        }
    };

    this.initialiseMap = function (opts) {
        const that = this;
        if (typeof opts == 'undefined') opts = {};
        if (typeof opts.lat == 'undefined') opts.lat = 0;
        if (typeof opts.lon == 'undefined') opts.lon = 0;
        if (typeof opts.zoom == 'undefined') opts.zoom = 0;
        if (typeof opts.mapTypeId == 'undefined') opts.mapTypeId = pwMap.MAPTYPE_TERRAIN;
        if (typeof opts.waypoints == 'undefined') opts.waypoints = null;
        if (typeof opts.elementId == 'undefined') {
            opts.elementId = "GoogleMap";
            opts.elementId2 = "GoogleMap2";
        }
        opts.zoom = Math.max(opts.zoom, minimumResolution);

        const mapOptions = {
            mapTypeId: opts.mapTypeId,
            lat: opts.lat,
            lon: opts.lon,
            zoom: opts.zoom,
            zoomControl: opts.zoomControl,
            maxBounds: opts.maxBounds,
            minZoom: minimumResolution,
            maxZoom: maximumResolution,
            forceClones: opts.forceClones,
            baseLayers: opts.baseLayers,
            baseLayerIndex: opts.baseLayerIndex
        };
        // Turn off Atlas rendering until we're sure everything is ready.
        if (window.pwMapDefaultAPI === "atlas") {
            window.Atlas.disableRender = true;
        }
        this.map1.initialise($j('#' + opts.elementId)[0], mapOptions);
        if (this.map1._atlasMap) {
            this.map1.addBase(this.map1BaseLayerGroup);
        }
        this.map1.addItem(this.map1ItemLayerGroup);
        this.map1.clickHandler.add(function (e) {
            that.clickHandler(e);
        });
        this.map1.viewportHandler.add(function () {
            that.viewportHandler();
        });
        this.map1.dragStarted.add(function () {
            that.dragStarted();
        });
        this.map1.dragEnded.add(function () {
            that.dragEnded();
        });
        this.map1.zoomChanged.add(function () {
            that.zoomChanged();
        });

        // We need to put off setting the map bounds until we know that the width and height are reasonable.
        // It's better not to turn on Atlas rendering until this is done.
        // Note also... whatever functions are already scheduled to run on map initialisation by the time
        // we reach this point, should also be run before we turn on map rendering, in the hope this saves
        // some time and UI flashing...

        function finishInitialising() {
            // this code may be called before module import is fully complete
            // (e.g. validation.js code that tries to interact with an iframe with a map in it)
            // which can mean that pwMap is unavailable. Best to wait for the Google Maps promise.
            Promise.resolve(window.pw_GoogleMapsReady).then(() => {
                console.log("PWGMap initialised.");
                _PWGMap_initListeners.fire();
            });
        }

        const delay = 20;

        function waitForAtlasCorrectSizeThenFinishInitialising() {
            const width = that.map1._atlasMap.projection.viewport.width;
            const height = that.map1._atlasMap.projection.viewport.height;
            if (!isNaN(width) && width > 0 && !isNaN(height) && height > 0) {
                window.Atlas.disableRender = false;
                finishInitialising();
            } else {
                setTimeout(waitForAtlasCorrectSizeThenFinishInitialising, delay);
            }
        }

        if (this.map1._atlasMap) {
            setTimeout(waitForAtlasCorrectSizeThenFinishInitialising, delay);
        } else {
            setTimeout(finishInitialising, 0);
        }
        $j('#route-toggles-dialog .close').on('click', function () {
            PWGMap.showRouteToggles(false);
        });
        $j('#toggle-routes-button').on('click', function () {
            const isDisplayNone = $j('#route-toggles-dialog').css('display') === 'none';
            PWGMap.showRouteToggles(isDisplayNone);
        });
        PWGMap_addInitListener(function () {
            if (opts.waypoints) {
                let viewBounds = null;
                for (let i = 0; i < opts.waypoints.length; i++) {
                    viewBounds = pwMap.utils.boundsExtend(viewBounds, opts.waypoints[i]);
                }
                that.setBounds(viewBounds);
            }
        });
        PWGMap_addInitListener(initTooltipFeatures);
        PWGMap_addInitListener(function() {
            $j('pw-routing-power-settings').each((key, element) => {
                    let fieldList = $j(element).prop('fieldList');
                    fieldList.map(fieldName => {
                        let field = $j(`*[name=${fieldName}]`),
                            fieldValue = field.val(),
                            converted = powerSettingsPreferenceConversion(fieldName, fieldValue);

                        $j(element).prop(fieldName, converted);
                        console.warn(`${fieldName} set to ${converted}, unconverted value is ${fieldValue}`)
                    });
                });

            $j('pw-routing-power-settings').on('commit-field', (e) => {
                let {fieldName, fieldValue} = e.detail;

                let converted = powerSettingsPreferenceConversion(fieldName, fieldValue, true),
                    field = $j(`*[name=${fieldName}]`);

                field.val(converted);

                field.trigger('change');
                console.warn(`${fieldName} commited to ${converted}, converted value was ${fieldValue}`)
            });
        })
    };

    this.routerInitialize = function (initialValues) {
        let opts;
        if ('lat' in initialValues && 'lon' in initialValues && 'zoom' in initialValues) {
            opts = {lat: initialValues.lat, lon: initialValues.lon, zoom: initialValues.zoom};
        } else {
            opts = {waypoints: initialValues.waypoints};
        }
        if ('elementId' in initialValues) {
            opts.elementId = initialValues.elementId;
        }
        if ('maxBounds' in initialValues) {
            opts.maxBounds = initialValues.maxBounds;
        }
        this.initialiseMap(opts);

        // call function below
        this.routerShowResults(initialValues);
    };

    const ROUTE_LINECOLOURS = {PWG: '#0000ff', PWE: '#ff0000', GFS: '#008000', ECMWF: '#000000'};

    this.updateRouteUIInfo = function (path) {
        if (!path.ui) {
            path.ui = {};
        }
        path.ui.label = this.getRouteLabel(path);
        path.ui.textColour = this.getRouteColour(path);
        path.ui.lineColour = this.getRouteLineColour(path);
        addWarningsToRoute(path.complete, ROUTING_INVALID_VALUE);
    };
    this.getRouteLabel = function (path) {
        if (path.params && path.params.label) {
            return path.params.label;
        }
        return getRouteSourceFromPath(path) || '';
    };
    this.getRouteColour = function (path) {
        if (path.params && path.params.colour) {
            return path.params.colour;
        }
        const routeSource = getRouteSourceFromPath(path);
        return ROUTE_COLOURS[routeSource] || '#000000';
    };
    this.getRouteLineColour = function (path) {
        if (path.params && path.params.lineColour) {
            return path.params.lineColour;
        }
        const routeSource = getRouteSourceFromPath(path);
        return ROUTE_LINECOLOURS[routeSource] || '#000000';
    };

    this.routerShowResults = function (initialValues) {
        const that = this;
        this.initialValues = initialValues;
        const routerCombinedOutput = combineRouterOutput(initialValues.routerOutput);
        const routeList = routerCombinedOutput.paths;

        this.routerOptimiseForChanged(initialValues.optimiseFor);

        let showMessages = true;
        if (isOffshoreApp) {
            const isActualRoutingPage = ROUTING_PAGE_NAMES.indexOf(app.getSetting('App_CurrentPage')) !== -1;
            showMessages = isActualRoutingPage && !app.getSetting('Routing_MessagesShown', false);
        }
        if (showMessages) {
            let errorMessages = '';
            for (let i = 0; i < routeList.length; i++) {
                let errorMessage = '';
                let warningMessage = '';
                const src = getRouteSourceFromPath(routeList[i]);
                let srcLabel = this.getRouteLabel(routeList[i]);
                const srcColour = this.getRouteColour(routeList[i]);
                const t = routeList[i].route.length && routeList[i].route[0].t || 0;
                if (src !== '') {
                    srcLabel = '<span style="color:' + srcColour + '">[' + srcLabel + ']</span>';
                    if (t) {
                        srcLabel += ' ' + formatTimestamp(t + utcOffset * 60 * 60);
                    }
                    srcLabel += ' ';
                }
                if (initialValues.optimiseFor === 'comfort') {
                    if (routeList[i].route.length >= 2) {
                        const lastEdge = routeList[i].route[routeList[i].route.length - 2];
                        const maxTws = convert(lastEdge.maxTws, units.metres_per_second, preference_units.wind_speed);
                        if (maxTws > initialValues.comfortWindSpeed) {
                            warningMessage += 'Wind comfort of ' + format1dp(initialValues.comfortWindSpeed) + ' ' + initialValues.speedUnitName + ' exceeded: ' + format1dp(maxTws) + ' ' + initialValues.speedUnitName + '.';
                        }
                        if (lastEdge.maxWh > initialValues.comfortWaveHeight) {
                            warningMessage += 'Wave comfort of ' + initialValues.comfortWaveHeight + ' Metres exceeded: ' + format1dp(lastEdge.maxWh) + ' Metres.';
                        }
                    }
                }
                if (routeList[i].errorMessage) {
                    errorMessage += routeList[i].errorMessage + ". <br>";
                }
                if (routeList[i].warningMessages) {
                    for (let j = 0; j < routeList[i].warningMessages.length; ++j) {
                        warningMessage += routeList[i].warningMessages[j] + ". <br>";
                    }
                }
                if (errorMessage === '' && routeList[i].errors && routeList[i].errors.indexOf('GENERAL_FAILURE') !== -1) {
                    errorMessage += "Routing failure. <br>";
                }
                if (errorMessage !== '') {
                    errorMessages += '<b>' + srcLabel + 'Routing Failure:</b><br>' + errorMessage + '<br>';
                }
                // use errorMessages dialog for both
                if (warningMessage !== '') {
                    errorMessages += '<b>' + srcLabel + "Routing Warning:</b><br>" + warningMessage + "<br>";
                }
            }
            if (errorMessages !== '') {
                $j('#id_routerErrors').html(errorMessages);
                if (!this.initialValues.hideMessages) {
                    $j('#id_routerErrorContainer').show();
                }
                if (isOffshoreApp) {
                    app.setSetting('Routing_MessagesShown', true);
                }
            } else {
                $j('#id_routerErrorContainer').hide();
            }
        }
        const toPath = function (coords) {
            const path = [];
            for (let i = 0; i < coords.length; i++) {
                path[i] = {lat: coords[i][0], lon: coords[i][1]};
            }
            return path;
        };
        const addPolyline = function (coords) {
            const polyline = pwMap.polyline({
                path: toPath(coords),
                lineColour: 'white',
                zIndex: pwMap.zLevels.raceBoundary
            });
            that.addItem(polyline);
        };
        const addPolygon = function (coords) {
            const polygon = pwMap.polygon({
                path: toPath(coords),
                lineColour: 'white',
                fillColour: 'white',
                fillOpacity: 0.2,
                zIndex: pwMap.zLevels.raceBoundary
            });
            that.addItem(polygon);
        };
        const addCircle = function (lat, lon) {
            const circle = pwMap.circle({
                lineColour: 'white',
                lineOpacity: 0.8,
                fillColour: 'white',
                fillOpacity: 0.35,
                centre: {lat: lat, lon: lon},
                radius: 40,
                zIndex: pwMap.zLevels.raceBoundary
            });
            that.addItem(circle);
        };

        // add America's Cup course boundary
        const showAmericasCup = false;
        if (showAmericasCup) {
            const americasCupStartCoords = [
                [37.818814, -122.474280],
                [37.821588, -122.447224],
                [37.828712, -122.447206],
                [37.828769, -122.458793],
                [37.818814, -122.474280]
            ];
            const americasCupEndCoords = [
                [37.813300, -122.396900],
                [37.813300, -122.409092],
                [37.809532, -122.404636],
                [37.807353, -122.400342],
                [37.803407, -122.401007],
                [37.805181, -122.395546],
                [37.813300, -122.396900]
            ];
            const americasCupCoords = [
                [37.818814, -122.474280],
                [37.811675, -122.464542],
                [37.809702, -122.461946],
                [37.809894, -122.440567],
                [37.810568, -122.431144],
                [37.813300, -122.422701],
                [37.813300, -122.409092],
                [37.813300, -122.396900],
                [37.823610, -122.398169],
                [37.828414, -122.401178],
                [37.823341, -122.421030],
                [37.821588, -122.447224],
                [37.818814, -122.474280]
            ];
            addPolyline(americasCupCoords);
            addPolygon(americasCupStartCoords);
            addPolygon(americasCupEndCoords);
            addCircle(37.812931, -122.452820);
            addCircle(37.823788, -122.400594);
            addCircle(37.813477, -122.461937);
        }

        const showFastnetObstructions = false;
        if (showFastnetObstructions) {
            const fastnetObstructions = [
                [   // TSS - North West of the Casquets
                    [50 + 2.65 / 60, -2 - 57.01 / 60], // A – 50 02’.65N | 002 57’.01W
                    [50 + 7.70 / 60, -2 - 27.80 / 60], // B – 50 07’.70N | 002 27’.80W
                    [49 + 51.80 / 60, -2 - 21.24 / 60], // C - 49 51’.80N | 002 21’.24W
                    [49 + 46.80 / 60, -2 - 50.41 / 60]  // D – 49 46’.80N | 002 50’.41W
                ], [ // TSS - Lands End
                    [50 + 20.03 / 60, -6 - 5.06 / 60], // A – 50 20’.03N | 006 05’.06W
                    [50 + 20.03 / 60, -5 - 49.58 / 60], // B – 50 20’.03N | 005 49’.58W
                    [50 + 0.99 / 60, -5 - 49.58 / 60], // C – 50 00’.99N | 005 49’.58W
                    [49 + 53.54 / 60, -6 - 5.06 / 60]  // D – 49 53’.54N | 006 05’.06W
                ], [ // TSS - South of Bishops Rock
                    [49 + 46.04 / 60, -6 - 29.52 / 60], // A – 49 46’.04N | 006 29’.52W
                    [49 + 46.04 / 60, -6 - 16.50 / 60], // B – 49 46’.04N | 006 16’.50W
                    [49 + 35.43 / 60, -6 - 16.50 / 60], // C – 49 35’.43N | 006 16’.50W
                    [49 + 35.53 / 60, -6 - 34.22 / 60]  // D – 49 35’.53N | 006 34’.22W
                ], [ // TSS -  West of the Isles of Scilly
                    [50 + 4.12 / 60, -6 - 48.58 / 60], // A – 50 04’.12N | 006 48’.58W
                    [50 + 1.22 / 60, -6 - 32.82 / 60], // B – 50 01’.22N | 006 32’.82W
                    [49 + 52.36 / 60, -6 - 36.78 / 60], // C – 49 52’.36N | 006 36’.78W
                    [49 + 52.36 / 60, -6 - 53.84 / 60]  // D – 49 52’.36N | 006 53’.84W
                ], [ // TSS - Fastnet Rock
                    [51 + 21.31 / 60, -9 - 36.62 / 60], // A – 51 21’.31N | 009 36’.62W
                    [51 + 22.90 / 60, -9 - 27.36 / 60], // B – 51 22’.90N | 009 27’.36W
                    [51 + 17.17 / 60, -9 - 24.54 / 60], // C – 51 17’.17N | 009 24’.54W
                    [51 + 15.42 / 60, -9 - 33.84 / 60]  // D – 51 15’.42N | 009 33’.84W
                ]
            ];
            for (let i = 0; i < fastnetObstructions.length; i++) {
                addPolygon(fastnetObstructions[i]);
            }
        }
        clearOutput(); // might be redundant but pretty safe
        this.clearRoutes(); // seems to be necessary
        const currentFormatting = [];
        for (let i = 0; i < routeList.length; i++) {
            currentFormatting[i] = {thickness: 2, opacity: 1.0, color: this.getRouteLineColour(routeList[i]), lineStyle: 'solid'};
        }

        if (routeList && routeList.length) {
            $j('#forecastLegendHead').show();
            this.plotRoutes(routeList, currentFormatting);
            if (this.getRoutingMode() === 'WeatherRouting') {
                showOutput('Calculated Route', routeList, currentFormatting);
                if (typeof initializeRouteAnimation != 'undefined') {
                    initializeRouteAnimation(routeList);
                }
            }
            if (this.getRoutingMode() === 'TripPlanning') {
                showDeparturePlanningSummary(routeList);
            }
            this.setActiveRoute(0);
        }

        for (let i = 0; i < document.forms.inputs.elements.length; i++) {
            const element = document.forms.inputs.elements[i];
            if (element.name == 'weatherScale' && document.forms.inputs.weatherSource.value != 'predictwind') {
                element.disabled = 'disabled';
            } else {
                element.disabled = '';
            }
        }

        // HACK, route overlays are shared with showSelectedTrackingGroup which calls clearRoutes
        // Removing polylines and markers from the array so they are not removed later.
        this._secretPlotRouteOverlays = this.plotRouteOverlays;
        this.plotRouteOverlays = [];
    };

    this.routerClearResults = function () {
//        $j('#id_routerErrorContainer').hide();
//        $j('#id_routerWarningContainer').hide();
        clearOutput(); // FIXME This is currently redundant... maybe don't do this when loading?
        // Remove shapes
        this.clearRoutes();
        this.plotRouteOverlays = this._secretPlotRouteOverlays;
        this.clearRoutes();
    };

    function clearOutput() {
        $j('#atmosphereResults,#currentResults,#routeResults,#summaryResults,#waveResults,#windResults,#graphedResultsContent')
            .each(function() {
                render(nothing, this);
            });
    }

    function tripStatistics(path, src) {
        const NA = '---';
        const route = path.route;
        const labelHtml = PWGMap.getSourceLongLabel(path);
        // NB: convention: min/max prefix is used to summarise stats with min/max rather than being averaged
        if (!route || route.length === 0) {
            return {
                src: src,
                path: path,
                weatherSource: path.weatherSource,
                labelHtml: labelHtml,
                startTime: NA,
                totalTime: NA,
                motoringTime: NA,
                fuelConsumed: path.params.useFuelOptimizer ? NA : null,
                minWindSpeed: NA,
                maxWindSpeed: NA,
                aveWindSpeed: NA,
                maxGust: NA,
                maxCAPE: NA,
                maxRain: NA,
                percentUpwind: NA,
                percentReaching: NA,
                percentDownwind: NA,
                percent0to8knots: NA,
                percent8to20knots: NA,
                percent20to30knots: NA,
                percent30to40knots: NA,
                percentOver40knots: NA,
                percentUpwindOver15Knots: NA,
                percentWindAgainstCurrent: NA,
                percent0to1m: NA,
                percent1to2m: NA,
                percent2to3m: NA,
                percent3to4m: NA,
                percent4to5m: NA,
                percentOver5m: NA,
                percentRollUnder4deg: NA
            };
        }
        const REACHING_ANGLE = 55;
        const DOWNWIND_ANGLE = 135;

        let sectionTime = 0;
        let totalTime = 0;
        let motoringTime = typeof route[0].motoring === 'undefined' ? null : 0;

        let minWindSpeed = 10000;
        let maxWindSpeed = 0;
        let sumWindSpeed = 0;
        let maxGust = 0;
        let maxCAPE = 0;
        let maxRain = 0;
        let timeUpwind = 0;
        let timeUpwindOver15Knots = 0;
        let timeWindAgainstCurrent = 0;
        let timeReaching = 0;
        let timeDownwind = 0;
        let time0to8knots = 0;
        let time8to20knots = 0;
        let time20to30knots = 0;
        let time30to40knots = 0;
        let timeOver40knots = 0;
        let time0to1m = 0;
        let time1to2m = 0;
        let time2to3m = 0;
        let time3to4m = 0;
        let time4to5m = 0;
        let timeOver5m = 0;
        let timeMissingWave = 0;
        let timeRollUnder4deg = 0;
        let timeRollOver4deg = 0;
        let timeVertAccUnder0_2g = 0;
        let timeVertAccOver0_2g = 0;
        let timeSlammingIncUnder50percent = 0;
        let timeSlammingIncOver50percent = 0;
        let timePolarUnder100percent = 0;
        let timePolarOver100percent = 0;
        const startTime = route[0].t + PWGMap.utcOffset * 3600;

        let lastNode = null;
        for (let i = 0; i < route.length; i++) {
            if (typeof route[i].twa === 'undefined') {
                continue;
            }

            if (lastNode) {
                const wave = lastNode.wh;
                const tws = lastNode.tws / 0.5144;
                const twa = Math.abs(lastNode.twa * 1);
                if (tws > maxWindSpeed) {
                    maxWindSpeed = tws;
                }
                if (tws < minWindSpeed) {
                    minWindSpeed = tws;
                }

                sectionTime = route[i].t - lastNode.t;
                totalTime += sectionTime;
                sumWindSpeed += tws * sectionTime;

                if (motoringTime !== null && lastNode.motoring) {
                    motoringTime += sectionTime;
                }

                if (isset(lastNode.gust, ROUTING_INVALID_VALUE) && lastNode.gust / 0.5144 > maxGust) {
                    maxGust = lastNode.gust / 0.5144;
                }
                if (isset(lastNode.cape, ROUTING_INVALID_VALUE) && lastNode.cape > maxCAPE) {
                    maxCAPE = lastNode.cape;
                }
                if (isset(lastNode.rain, ROUTING_INVALID_VALUE) && lastNode.rain > maxRain) {
                    maxRain = lastNode.rain;
                }

                if (isset(tws, ROUTING_INVALID_VALUE)) {
                    if (tws < 8) time0to8knots += sectionTime;
                    else if (tws < 20) time8to20knots += sectionTime;
                    else if (tws < 30) time20to30knots += sectionTime;
                    else if (tws < 40) time30to40knots += sectionTime;
                    else timeOver40knots += sectionTime;
                }

                if (isset(wave, ROUTING_INVALID_VALUE)) {
                    if (wave < 1) time0to1m += sectionTime;
                    else if (wave < 2) time1to2m += sectionTime;
                    else if (wave < 3) time2to3m += sectionTime;
                    else if (wave < 4) time3to4m += sectionTime;
                    else if (wave < 5) time4to5m += sectionTime;
                    else timeOver5m += sectionTime;
                } else {
                    // include the range of time when invalid wave data is present
                    timeMissingWave += sectionTime;
                }

                if (isset(lastNode.cd, ROUTING_INVALID_VALUE) &&
                    isset(lastNode.cs, ROUTING_INVALID_VALUE) &&
                    isset(lastNode.gws, ROUTING_INVALID_VALUE) &&
                    isset(lastNode.gwd, ROUTING_INVALID_VALUE))
                {
                    const cs = lastNode.cs / 0.5144;
                    const cd = lastNode.cd;
                    const gwd = lastNode.gwd;
                    const gws = lastNode.gws / 0.5144;
                    const d = (gwd - cd + 3600) % 360;
                    const windAgainstCurrent = d >= 300 || d <= 60;     // up to 60 degrees difference between current direction and gwd
                    if (windAgainstCurrent && gws >= 12 && cs >= 1) { // but only when wind speed is 12+ knots and current speed is 1+ knots
                        timeWindAgainstCurrent += sectionTime;
                    }
                }

                const roll = lastNode.roll;
                if (isset(roll, ROUTING_INVALID_VALUE)) {
                    if (roll < 4) {
                        timeRollUnder4deg += sectionTime;
                    } else {
                        timeRollOver4deg += sectionTime;
                    }
                }
                const vertacc = lastNode.vertacc;
                if (isset(vertacc, ROUTING_INVALID_VALUE)) {
                    if (vertacc < 0.2) {
                        timeVertAccUnder0_2g += sectionTime;
                    } else {
                        timeVertAccOver0_2g += sectionTime;
                    }
                }
                const slaminc = lastNode.slaminc;
                if (isset(slaminc, ROUTING_INVALID_VALUE)) {
                    if (slaminc < 50) {
                        timeSlammingIncUnder50percent += sectionTime;
                    } else {
                        timeSlammingIncOver50percent += sectionTime;
                    }
                }
                const stwinwaves = lastNode.stwinwaves;
                if (isset(stwinwaves, ROUTING_INVALID_VALUE)) {
                    if (stwinwaves < 100) {
                        timePolarUnder100percent += sectionTime;
                    } else {
                        timePolarOver100percent += sectionTime;
                    }
                }

                if (twa < REACHING_ANGLE) {
                    timeUpwind += sectionTime;
                    if (tws >= 15) {
                        timeUpwindOver15Knots += sectionTime;
                    }
                } else {
                    if (twa >= DOWNWIND_ANGLE) {
                        timeDownwind += sectionTime;
                    } else {
                        timeReaching += sectionTime;
                    }
                }

            }
            lastNode = route[i];
        }
        const aveWindSpeed = sumWindSpeed / totalTime;

        return {
            src: src,
            path: path,
            labelHtml: labelHtml,
            startTime: startTime,
            totalTime: totalTime / 60 / 60,
            fuelConsumed: (path.params.useFuelOptimizer ? route[route.length - 1].accumfuelconsumed : null) ?? null,
            motoringTime: motoringTime !== null ? motoringTime / 60 / 60 : null,
            minWindSpeed: minWindSpeed,
            maxWindSpeed: maxWindSpeed,
            aveWindSpeed: aveWindSpeed,
            maxGust: maxGust,
            maxCAPE: maxCAPE,
            maxRain: maxRain,
            percentUpwind: timeUpwind / totalTime,
            percentReaching: timeReaching / totalTime,
            percentDownwind: timeDownwind / totalTime,
            percent0to8knots: time0to8knots / totalTime,
            percent8to20knots: time8to20knots / totalTime,
            percent20to30knots: time20to30knots / totalTime,
            percent30to40knots: time30to40knots / totalTime,
            percentOver40knots: timeOver40knots / totalTime,
            percentUpwindOver15Knots: timeUpwindOver15Knots / totalTime,
            percentWindAgainstCurrent: timeWindAgainstCurrent / totalTime,
            percent0to1m: time0to1m / totalTime,
            percent1to2m: time1to2m / totalTime,
            percent2to3m: time2to3m / totalTime,
            percent3to4m: time3to4m / totalTime,
            percent4to5m: time4to5m / totalTime,
            percentOver5m: timeOver5m / totalTime,
            percentMissingWave: timeMissingWave / totalTime,
            percentRollUnder4deg: timeRollUnder4deg / totalTime,
            percentRollOver4deg: timeRollOver4deg / totalTime,
            percentVerticalAccUnder0_2g: timeVertAccUnder0_2g / totalTime,
            percentVerticalAccOver0_2g: timeVertAccOver0_2g / totalTime,
            percentSlammingIncUnder50percent: timeSlammingIncUnder50percent / totalTime,
            percentSlammingIncOver50percent: timeSlammingIncOver50percent / totalTime,
            percentPolarUnder100percent: timePolarUnder100percent / totalTime,
            percentPolarOver100percent: timePolarOver100percent / totalTime
        };
    }

    function showDeparturePlanningSummary(routeList) {
        const tripStats = routeList.map(obj => tripStatistics(obj, getRouteSourceFromPath(obj)));
        render(
            html`
              <pw-routing-departure-planning-tables .routeList=${routeList}
                                                    .stats=${tripStats}
                                                    .utcOffset=${PWGMap.utcOffset}
                                                    isAggregate>
            `,
            $j('#windResults')[0]
        );
        // FIXME we should be add this listener just once, I think
        $j('.planner-details-button')
            .off('click')
            .on('click', function () {
                $j('pw-routing-departure-planning-tables').prop('isAggregate', !this.classList.toggle('planner-details-button-active'));
            });
    }


    function showOutput(title, routeList, formatting) {
        const ws = PWGMap.weatherSource;
        const defaultSources = ws == "predictwind" ? ['PWG', 'PWE', 'GFS', 'ECMWF'] : ['GFS', 'ECMWF', 'PWG', 'PWE'];
        let totalSamples = 0;

        $j.each(routeList, function (index) {
            this.complete = this.forecast.length ? this.route.concat(this.forecast) : this.route;
            totalSamples += this.complete.length;
            if (this.src) {
                if (this.weatherSource === "predictwind") {
                    if (this.src === "GFS") {
                        this.src = "PWG";
                    }
                }
            } else {
                this.src = defaultSources[index];
            }
        });
        // Note weird looking selector here that has to work on Offshore App and website/smartphone...
        // Also, off() is used to remove any existing click handler, so we can change between datasets.
        $j('#tab-routing-graph, a.tab-title[data-tab-name=graph]')
            .off('click.graphs')
            .one('click.graphs', function () {
                if (totalSamples > 0) {
                    let waveFilter = $j('.routing-waves-filter').data('routing-waves-filter');
                    graphResults(routeList, formatting, waveFilter);
                }
            });
        // The order of the routes should now be reflected everywhere; FIXME we can sort them if we want?
        if (totalSamples > 0) {
            $j('#hintPlaceholder_lowResolution').empty().append($j('<div>', {id: 'hint_lowResolution'}));
            $j('#hintPlaceholder_weatherRoutingEmail').empty().append($j('<div>', {id: 'hint_weatherRoutingEmail'}));

            // update the value of the hidden timezone form field used to export routes, which is only populated on page load
            // FIXME: there's an element with id 'timezone' which we get back with window.timezone UNLESS we have
            // already assigned the global variable. Better to use module variables or callbacks somehow.
            if (typeof timezone === 'string') {
                $j('input[type="hidden"][name="timezone"]').val(window.timezone);
            }
            checkTimezone();

            const stats = routeList.map(tripStatistics);
            routeList.forEach(routeItem => PWGMap.updateRouteUIInfo(routeItem));

            // #summaryResults
            // Use <div> wrapper element to avoid legacy .tab-content > * style rules :(
            render(
                html`
                  <div>
                    <pw-routing-summary-units .routeList=${routeList}></pw-routing-summary-units>
                    <pw-routing-summary-comfort-settings .routeList=${routeList}></pw-routing-summary-comfort-settings>
                    <pw-routing-summary-tables .routeList=${routeList}
                                               .stats=${stats}
                                               .utcOffset=${PWGMap.utcOffset}></pw-routing-summary-tables>
                  </div>
                `,
                $j('#summaryResults')[0]
            );

            // #waveResults
            // Use <div> wrapper element to avoid legacy .tab-content > * style rules :(
            const showWaveEffects = !document.body.classList.contains('DestinationForecast');
            render(
                html`
                  <div>
                    <pw-routing-waves-units-table ?showWaveEffects=${showWaveEffects}></pw-routing-waves-units-table>
                    <div id=hint_waveOutputHeadings>
                      <pw-routing-waves-tables .routeList=${routeList}
                                               .utcOffset=${PWGMap.utcOffset}
                                               ?showWaveEffects=${showWaveEffects}
                                               .waveFilter=${$j('.routing-waves-filter').data('routing-waves-filter')}
                      ></pw-routing-waves-tables>
                    </div>
                  </div>
                `,
                $j('#waveResults')[0]
            );

            // #windResults
            render(
                html`
                    <div id=hint_windOutputHeadings>
                      <pw-routing-wind-notes></pw-routing-wind-notes>
                      <pw-routing-wind-units .routeList=${routeList}></pw-routing-wind-units>
                      <pw-routing-wind-tables .routeList=${routeList}
                                              .stats=${stats}
                                              .utcOffset=${PWGMap.utcOffset}></pw-routing-wind-tables>
                    </div>
                `,
                $j('#windResults')[0]
            );

            // #routeResults
            render(
                html`
                    <p>
                      ${gettext("The weather route has been routed in water deeper than your selected depth contour. The weather route still should be checked against navigational charts for rocks and other hazards.")}
                    </p>
                    <br>
                    <div id=hint_routeOutputHeadings>
                      <pw-routing-route-tables .routeList=${routeList}
                                                .utcOffset=${PWGMap.utcOffset}></pw-routing-route-tables>
                    </div>
                `,
                $j('#routeResults')[0]
            );

            // #atmosphereResults
            render(
                html`
                    <div id=hint_atmosphereOutputHeadings>
                      <pw-routing-atmosphere-units></pw-routing-atmosphere-units>
                      <pw-routing-atmosphere-tables .routeList=${routeList}
                                                    .utcOffset=${PWGMap.utcOffset}></pw-routing-atmosphere-tables>
                    </div>
                `,
                $j('#atmosphereResults')[0]
            );

            // #currentResults
            let showCurrents = canShowCurrents(routeList);
            render(
                showCurrents ? html`
                    <div id=hint_currentOutputHeadings>
                      <pw-routing-current-units></pw-routing-current-units>
                      <pw-routing-current-tables .routeList=${routeList}
                                                 .utcOffset=${PWGMap.utcOffset}></pw-routing-current-tables>
                    </div>
                ` : nothing,
                $j('#currentResults')[0]
            );
            $j('#currentTab').toggle(showCurrents);
            if (!showCurrents) {
                if ($j('#tab-routing-current').is(':checked')) {
                    $j('#tab-routing-graph').trigger('click');
                }
            }

            // #exportResults FIXME mostly in static HTML, figure out how to improve this
            $j('#exportResults').each(function () {
                if (!$j(this).children('p').length) {
                    $j(this).prepend(
                        $j('<p>').text(gettext("The weather route has been routed in water deeper than your selected depth contour. The weather route still should be checked against navigational charts for rocks and other hazards.")),
                        '<br>'
                    );
                }
            });
            $j('#windTab').append($j('<span>', {id: "hint_waypoints"}));
            window.showPageHints?.();
        }
    }

    function formatCsvRow(label, values, formatting, requireAll) {
        if (requireAll) {
            for (let i = 0; i < values.length; i++) {
                if (values[i] === null) {
                    return '';
                }
            }
        }
        let text = '';
        for (let i = 0; i < values.length; i++) {
            const value = values[i];
            if (value !== null) {
                if (text !== '') text += ',&nbsp;</td><td>';
                if (formatting && formatting.length > i && formatting[i]) {
                    if (formatting[i].indexOf('$') >= 0) {
                        text += formatting[i].replace('$', value);
                    } else {
                        text += value + formatting[i];
                    }
                } else {
                    text += value;
                }
            }
        }
        if (text !== '') {
            let prefix = '<tr><td>';
            if (label !== '') {
                if (values.length === 1) {
                    prefix = '<tr><td style="text-align:left">' + label + ':&nbsp;</td><td colspan="2">';
                } else {
                    prefix = '<tr><td style="text-align:left">' + label + ':&nbsp;</td><td>';
                }
            }
            text = prefix + text + '&nbsp;</td></tr>';
        }
        return text;
    }

    function interpolateNodes(a, b, t) {
        t = Math.max(0, Math.min(t, 1));
        const s = 1.0 - t;
        const result = {
            interpolated: true,
            interpolatedA: a,
            interpolatedB: b,
            interpolatedT: t,
            isSample: a.isSample && b.isSample,
            motoring: a.motoring,
            device: t < 0.5 ? a.device : b.device
        };
        if (isset(a.p) && isset(a.p.lat) && isset(a.p.lon) && isset(b.p) && isset(b.p.lat) && isset(b.p.lon)) {
            result.p = {
                lat: a.p.lat * s + b.p.lat * t,
                lon: a.p.lon * s + b.p.lon * t
            };
        } else {
            result.p = null;
        }
        const linearFields = ['t', 'gust', 'bsp', 'press', 'temp', 'rain', 'cape', 'accumfuelconsumed'];
        for (let i = 0; i < linearFields.length; i++) {
            const field = linearFields[i];
            if (isset(a[field]) && isset(b[field])) {
                result[field] = a[field] * s + b[field] * t;
            } else {
                result[field] = null;
            }
        }
        const polarFields = [['tws', 'twd'], ['wh', 'wd'], ['cs', 'cd']];
        for (let i = 0; i < polarFields.length; i++) {
            const mag = polarFields[i][0];
            const dir = polarFields[i][1];
            if (isset(a[mag]) && isset(b[mag]) && isset(a[dir]) && isset(b[dir])) {
                const ax = a[mag] * Math.cos(a[dir] * 3.14159265 / 180);
                const ay = a[mag] * Math.sin(a[dir] * 3.14159265 / 180);
                const bx = b[mag] * Math.cos(b[dir] * 3.14159265 / 180);
                const by = b[mag] * Math.sin(b[dir] * 3.14159265 / 180);
                const x = ax * s + bx * t;
                const y = ay * s + by * t;
                result[mag] = Math.sqrt(x * x + y * y);
                result[dir] = Math.atan2(y, x) * 180 / 3.14159265;
                if (result[dir] < 0) {
                    result[dir] += 360;
                }
            } else {
                result[mag] = null;
                result[dir] = null;
            }
        }
        result['bearing'] = a['bearing'];
        if (isset(result['bearing']) && isset(result['twd'])) {
            result['twa'] = result['twd'] - result['bearing'];
            if (result['twa'] > 180) {
                result['twa'] -= 360;
            }
            if (result['twa'] < -180) {
                result['twa'] += 360;
            }
        } else {
            result['twa'] = null;
        }
        // fallback for unhandled values, copy from a
        for (const key in a) {
            if (typeof result[key] === 'undefined') {
                result[key] = a[key];
            }
        }
        return result;
    }

    function showWindSampleOnMap(node, optFormatting, optFooter) {
        if (showWindSampleOnMapCore(node, optFormatting, optFooter)) {
            PWGMap.setCurrentRouteFromNode(node);
            if (PWGMap.showWindSampleOnMapCallback) {
                PWGMap.showWindSampleOnMapCallback(node);
            }
        }
    }

    function getWindSampleHtml(node, optFormatting) {
        if (!node || node.twa === 0 && node.tws === 0 && node.twd === 0 && !node.isSample || typeof node.p == 'undefined') {
            return null;
        }
        const point = node.p?.lat !== undefined ? node.p : null;
        const {converted, formatted, units:nodeUnits} = getUserNode(node, {show_units:true});

        const formattedTwa = formatted.twa ? html`${unsafeHTML(formatted.twa)} twa` : '';
        const formattedGust = formatted.gust ? interpolate(pgettext('gust speed (leave $ as placeholder)', 'gusts to $').replace('$', '%s'), [formatted.gust]) : '';
        const formattedWp = (converted.wp ?? null) !== null ? interpolate(pgettext('frequency (leave $ as placeholder)', 'every $ secs').replace('$', '%s'), [converted.wp.toFixed(1)]) : '';

        let formattedETA = null;
        if (optFormatting && optFormatting.route?.length
            && isset(optFormatting.destination?.lat) && isset(optFormatting.destination?.lon)) {
            let totalRouteSpanSpeed = 0,
                routeSpanDays = 7,
                routeLength = optFormatting.route.length,
                latestTimestamp = optFormatting.route[routeLength - 1].t,
                routeSpanStart = new Date(latestTimestamp*1000),
                routeSpanCount = 0;
            routeSpanStart.setDate(routeSpanStart.getDate() - routeSpanDays);
            for (let i = routeLength - 1; i >= 0; i--) {
                let node = optFormatting.route[i]
                if (node.t > routeSpanStart.getTime()/1000) {
                    totalRouteSpanSpeed += node.bsp;
                    routeSpanCount += 1;
                } else {
                    break;
                }
            }
            if (totalRouteSpanSpeed) {
                // Distance unit is nautical miles
                const distanceToDestination = pwMap.utils.haversineDistance(point, optFormatting.destination),
                    averageRouteSpeed = convert(totalRouteSpanSpeed / routeSpanCount, GET_FIELD_UNIT(nodeUnits.fields.bsp), units.knots),
                    etaTimestamp = latestTimestamp + 3600 * distanceToDestination / averageRouteSpeed;
                formattedETA = formatWeatherTimestamp(etaTimestamp, PWGMap.utcOffset);
            }
        }

        let fontSize = '0.75rem';
        let swatchSize = '1.375rem';
        let swatchMarginRight = '0.125rem';
        let deviceFontSize = '0.625rem';
        if (isOffshoreApp) {
            fontSize = 12 / 96 + 'in';
            deviceFontSize = 10 / 96 + 'in';
        }

        let boatType = '';
        if (optFormatting?.boatType) {
            boatType = ' — ' + optFormatting.boatType;
        }

        const swatchHtml = html`${ optFormatting?.name && optFormatting?.color
            ? html`<div style="float:left; margin-right:${swatchMarginRight}; width:${swatchSize}; height:${swatchSize}; border:1px solid black; background-color:${optFormatting.color}"></div>`
            : ''}`;

        const nameHtml = html`${optFormatting?.name
            ? html`${optFormatting.uName?.startsWith('yellowbrick-')
                ? html`<b style="display:inline-block; margin-bottom:0.25rem">${optFormatting.name}${boatType}</b><br style="clear:both;">`
                : html`<a href="https://forecast.predictwind.com/tracking/display/${optFormatting.uName}"><b
                                style="display:inline-block; margin-bottom:0.25rem">${optFormatting.name}${boatType}</b><br style="clear:both;"></a>`
            }`
            : nothing}`;

        const timeHtml = html`${ formatted.time !== null
            ? html`<b>${formatted.time}</b><br>`
            : nothing }`;

        const coordsHtml = html`${ formatted.lat !== null && formatted.lon !== null
            ? html`<span>${formatted.lat}&nbsp;&nbsp;${formatted.lon}</span><br>`
            : nothing }`;

        const fuelConsumptionHtml = formatted.accumfuelconsumed && shouldShowFuelConsumption()
            ? html`<tr> <td style="text-align:left">${gettext('Fuel')}:&nbsp;</td>
                        <td colspan="2">${formatted.accumfuelconsumed}&nbsp;&nbsp;</td></tr>`
            : nothing;

        const motoringHtml = typeof node.motoring === 'undefined' ? nothing : html`
            <tr><td style="text-align:left">${gettext('Motoring')}:&nbsp;</td><td colspan="2">${node.motoring ? 'M' : ' '}&nbsp;&nbsp;</td></tr>`;

        const windHtml = !formatted.tws ? nothing : html`
            <tr><td style="text-align:left">${gettext('Wind')}:&nbsp;</td>
                <td>${formatted.tws},&nbsp;</td>
                <td>${formatted.twd},&nbsp;</td>
                <td>${formattedGust ?? formattedTwa}</td></tr>`;

        const courseTwaHtml = formattedGust ? formattedTwa : ''; // twa only if gust shown above
        const courseHtml = !formatted.heading ? nothing : html`
            <tr><td style="text-align:left">${gettext('Course')}:&nbsp;</td>
                <td>${formatted.heading},&nbsp;</td>
                ${courseTwaHtml ? html`<td>${formatted.bsp}, &nbsp;</td><td>${courseTwaHtml}</td></tr>`
                                : html`<td>${formatted.bsp}</td>`
                }</tr>`;

        const destinationHtml = !isset(optFormatting?.destination?.lat) || !isset(optFormatting?.destination?.lon) ? nothing : html`
            <tr><td style="text-align:left">${gettext('Destination')}:&nbsp;</td>
                <td>${optFormatting.destination.lat}&deg;,&nbsp;</td>
                <td>${optFormatting.destination.lon}&deg;,&nbsp;</td></tr>`;

        const destinationEtaHtml = !formattedETA ? nothing : html`
            <tr><td style="text-align:left">${gettext('ETA')}:&nbsp;</td>
                <td>${formattedETA}&nbsp;&nbsp;</td></tr>`;

        const currentHtml = !formatted.cs ? nothing : html`
            <tr><td style="text-align:left">${pgettext('ocean current', 'Current')}:&nbsp;</td>
                <td>${formatted.cs},&nbsp;</td>
                <td>${formatted.cd}&nbsp;&nbsp;</td></tr>`;

        const waveHtml = !formatted.wh ? nothing : html`
            <tr><td style="text-align:left">${gettext('Waves')}:&nbsp;</td>
                <td>${formatted.wh},&nbsp;</td>
                <td>${formatted.wd},&nbsp;</td>
                <td>${formattedWp}</td></tr>`;

        const pressureHtml = !formatted.press ? nothing : html`
            <tr><td style="text-align:left">${gettext('Pressure')}:&nbsp;</td>
                <td colspan="2">${formatted.press}&nbsp;&nbsp;</td></tr>`;

        const temperatureHtml = formatted.temp === null ? nothing : html`
            <tr><td style="text-align:left">${gettext('Temperature')}:&nbsp;</td>
                <td colspan="2">${formatted.temp}&nbsp;&nbsp;</td></tr>`;

        const rainHtml = formatted.rain === null ? nothing : html`
            <tr><td style="text-align:left">${gettext('Rain')}:&nbsp;</td>
                <td colspan="2">${formatted.rain}&nbsp;&nbsp;</td></tr>`;

        const capeHtml = formatted.cape === null ? nothing : html`
            <tr><td style="text-align:left">${gettext('CAPE')}:&nbsp;</td>
                <td colspan="2">${formatted.cape}&nbsp;&nbsp;</td></tr>`;

        let deviceHtml = '';
        if (node.device) {
            let deviceTypeName = node.device.deviceType;
            if (deviceTypeName == 'IOS') deviceTypeName = 'iOS';
            if (deviceTypeName == 'ANDR') deviceTypeName = 'Android';
            deviceHtml = html`
                <br>
                <div style="font-size:${deviceFontSize}">
                    <b>${node.device.deviceName}</b> (${node.device.deviceModel})<br>
                    ${node.device.appName} ${node.device.appVersion},&nbsp;&nbsp;${deviceTypeName} ${node.device.deviceVersion}
                </div>`;
        }
        const sampleHtml = html`
            <div style="white-space:nowrap; font-size:${fontSize}; position:relative">
                ${ swatchHtml }
                ${ nameHtml }
                ${ timeHtml }
                ${ coordsHtml }
                <table style="margin:0; border-spacing:0; text-align:right">
                    ${ fuelConsumptionHtml }
                    ${ motoringHtml }
                    ${ windHtml }
                    ${ courseHtml }
                    ${ destinationHtml }
                    ${ destinationEtaHtml }
                    ${ currentHtml }
                    ${ waveHtml }
                    ${ pressureHtml }
                    ${ temperatureHtml }
                    ${ rainHtml }
                    ${ capeHtml }
                </table>
                ${ deviceHtml }
            </div>`;

        return sampleHtml;
    }

    function showWindSampleOnMapCore(node, optFormatting, optFooter) {
        const sampleHtml = getWindSampleHtml(node, optFormatting);
        if (!sampleHtml) {
            PWGMap.map1.closePopup();
            return false;
        }
        const point = node.p?.lat !== undefined ? node.p : null;
        const elem = PWGMap.map1.openPopup(point, '');
        render(html`${sampleHtml}${unsafeHTML(optFooter)}`, elem);
        return true;
    }

    this.showWindSampleOnMap = showWindSampleOnMap;
    this.showWindSampleOnMapCore = showWindSampleOnMapCore;
    this.interpolateNodes = interpolateNodes;

    this.moveMarker = function (marker, opts) {
        pwMap.Marker.setPosition(marker, opts);
    };

    this.makeMarkerIcon = function (opts, icon) {
        return pwMap.icon(opts, icon);
    };
    this.setMarkerIcon = function (marker, icon) {
        pwMap.Marker.setIcon(marker, icon);
    };

    this.addMarker = function (opts, marker) {
        const returnOpts = {};
        marker = pwMap.marker(opts, marker, returnOpts);
        if (returnOpts.hidden) {
            this.removeItem(marker);
        } else {
            this.addItem(marker);
        }
        return marker;
    };

    this.addCircleMarker = function (opts, circleMarker) {
        const returnOpts = {};
        circleMarker = pwMap.circleMarker(opts, circleMarker, returnOpts);
        if (returnOpts.hidden) {
            this.removeItem(circleMarker);
        } else {
            this.addItem(circleMarker);
        }
        return circleMarker;
    };

    this.addRectangle = function (opts, opts2) {
        const rectangle = pwMap.rectangle(opts, opts2);
        if (opts.hidden) {
            this.removeItem(rectangle);
        } else {
            this.addItem(rectangle);
        }
        return rectangle;
    };

    this.removeElement = function (element) {
        this.removeItem(element);
    };
    this.removeMarker = function (marker) {
        this.removeItem(marker);
    };
    this.removeRectangle = function (rectangle) {
        this.removeItem(rectangle);
    };

    function makeCircleImageDataUrl(colour, radius) {
        try {
            const ratio = window.devicePixelRatio || 1;
            const canvas = document.createElement("canvas");
            canvas.width = radius * 2 * ratio;
            canvas.height = radius * 2 * ratio;
            const context = canvas.getContext("2d");
            context.scale(ratio, ratio);
            context.beginPath();
            context.arc(radius, radius, radius - 1, 0, 2 * Math.PI, false);
            context.fillStyle = colour;
            context.fill();
            // Use thinner outlines on retina
            context.lineWidth = 1 / ratio;
            context.strokeStyle = "black";
            context.stroke();
            return canvas.toDataURL("image/png");
        } catch (ex) {
            return STATIC_URL + 'images/gmap_marker_route7.png'; // white
        }
    }

    function makeBoatMarkerIcon(format) {
        if (!(format.color in routeIcons)) {
            const radius = {
                "blue": 7,
                "red": 6,
                "yellow": 4
            }[format.color] || 5;
            routeIcons[format.color] = makeWaypointIcon(makeCircleImageDataUrl(format.color, radius), undefined, 2 * radius);
        }
        return routeIcons[format.color];
    }

    function polylineCoordsFromRoute(route, minTime, maxTime, maxSectionTime, nodesOut, nodePositionsOut) {
        // FIXME I'm 99% sure that maxSectionTime is never anything other than undefined.
        const polylines = [];
        let polylineCoords = [];
        for (let i = 0; i < route.length; i++) {
            let lat = route[i].p.lat;
            let lon = route[i].p.lon;
            if (minTime != undefined && i < route.length - 1) {
                if (route[i + 1].t < minTime) {
                    continue;
                } else if (route[i].t < minTime) {
                    let t = (minTime - route[i].t) / (route[i + 1].t - route[i].t);
                    lat = route[i].p.lat + t * (route[i + 1].p.lat - route[i].p.lat);
                    lon = route[i].p.lon + t * (route[i + 1].p.lon - route[i].p.lon);
                }
            }
            if (maxTime != undefined && i > 0) {
                if (minTime != undefined && maxTime < minTime) {
                    break;
                }
                if (route[i - 1].t > maxTime) {
                    break;
                } else if (route[i].t > maxTime) {
                    let t = (maxTime - route[i - 1].t) / (route[i].t - route[i - 1].t);
                    lat = route[i - 1].p.lat + t * (route[i].p.lat - route[i - 1].p.lat);
                    lon = route[i - 1].p.lon + t * (route[i].p.lon - route[i - 1].p.lon);
                }
            }
            if (maxSectionTime && i > 0 && (route[i].t - route[i - 1].t) > maxSectionTime) {
                if (polylineCoords.length > 0) {
                    polylines.push(polylineCoords);
                }
                polylineCoords = [];
            }
            const position = {lat: lat, lon: lon};
            polylineCoords.push(position);
            if (nodesOut && route[i].twa !== undefined) {
                nodesOut.push(route[i]);
                nodePositionsOut.push(position);
            }
        }
        if (polylineCoords.length > 0) {
            polylines.push(polylineCoords);
        }
        return polylines;
    }

    function polylineWithFormat(polylineCoords, polylineFormatting, zIndex, group, clickhandler) {
        const polylineOptions = {
            path: polylineCoords,
            lineColour: polylineFormatting.color,
            lineWeight: polylineFormatting.thickness,
            lineOpacity: polylineFormatting.opacity,
            zIndex: zIndex || pwMap.zLevels.routePath,
            click: clickhandler
        };
        const polyline = pwMap.polyline(polylineOptions);
        if (group) {
            pwMap.LayerGroup.addItem(group, polyline);
        } else {
            PWGMap.addItem(polyline);
        }
        return polyline;
    }

    this.showRouteNodePopupAtTime = function (time) {
        if (this._routeIndex !== null) {
            if (this.displayedRouteInfo) {
                if (PWGMap.map1.hasPopup() || this.displayedRouteInfo.popupTemporarilyClosed) {
                    this.displayedRouteInfo.popupTemporarilyClosed = false;
                    if (this._routeIndex < this.displayedRouteInfo.processedList.length) {
                        const nodes = this.displayedRouteInfo.processedList[this._routeIndex].nodes;
                        for (let i = 0; i < nodes.length - 1; i++) {
                            const t0 = nodes[i].t;
                            const t1 = nodes[i + 1].t;
                            if (time >= t0 && time <= t1) {
                                const node = interpolateNodes(nodes[i], nodes[i + 1], (time - t0) / (t1 - t0));
                                showWindSampleOnMapCore(node, this.displayedRouteInfo.formatting[i]);
                                return;
                            }
                        }
                    }
                    this.displayedRouteInfo.popupTemporarilyClosed = true;
                    PWGMap.map1.closePopup();
                }
            }
        }
    };

    this.getRoutingMode = function () {
        // one of 'WeatherRouting', 'TripPlanning', 'DestinationForecast'
        if (this.displayedRouteInfo) {
            const routeList = this.displayedRouteInfo.routeList;
            if (routeList.length > 0 && routeList[0].params) {
                return routeList[0].params.routingMode || 'WeatherRouting';
            }
        }
        return 'WeatherRouting';
    };

    function getRouteSourceFromPath(path) {
        if (!path || !path.src) return 'PWG';
        const src = path.src;
        if (src === 'GFS' && path.weatherSource === 'predictwind') {
            return 'PWG';
        }
        if (src === 'PWC') return 'PWE';
        if (src === 'CMC') return 'ECMWF';
        if (isOffshoreApp && src === 'SPIREBETA') return 'SPIRE';
        if (src === 'SPIRE') return 'SPIREBETA';
        return src;
    }

    const ROUTING_PAGE_NAMES = ['WeatherRouting', 'TripPlanning', 'DestinationForecast'];

    this._routingPageName = null;
    this.setRoutingPageName = function (pageName) {
        if (ROUTING_PAGE_NAMES.indexOf(pageName) !== -1) {
            this._routingPageName = pageName;
        } else {
            this._routingPageName = null;
        }
    };

    this.getRouteDisplayInfo = function (routeIndex, mapSource) {
        const routeDisplayInfo = {
            icon: PWGMap.boatIcon,
            zIndex: pwMap.zLevels.routePosition,
            visible: true
        };
        let path = null,
            routeSource = null,
            routeMapSource;
        let routeVisible = true;
        if (this.displayedRouteInfo) {
            const routeList = this.displayedRouteInfo.routeList;
            if (routeIndex >= 0 && routeIndex < routeList.length) {
                path = routeList[routeIndex];
                routeSource = getRouteSourceFromPath(path);
                routeMapSource = this.getMapSourceForRouteSource(routeSource);
                routeVisible = this.displayedRouteInfo.processedList[routeIndex].visible;
            }
        }
        if (path) {
            if (this._routingPageName === 'WeatherRouting') {
                if (routeIndex === this._routeIndex) {
                    routeDisplayInfo.icon = this.boatIcon;
                    routeDisplayInfo.zIndex = pwMap.zLevels.routePosition + 1;
                    routeDisplayInfo.visible = routeVisible;
                } else {
                    routeDisplayInfo.icon = this.boatIconDot;
                    routeDisplayInfo.visible = routeVisible;
                }
            }
            if (this._routingPageName === 'TripPlanning') {
                if (routeMapSource === mapSource) {
                    routeDisplayInfo.icon = this.boatIcon;
                    routeDisplayInfo.visible = routeVisible;
                } else {
                    routeDisplayInfo.visible = false;
                }
            }
            if (this._routingPageName === 'DestinationForecast') {
                routeDisplayInfo.visible = false;
            }
        }
        return routeDisplayInfo;
    };


    this._boatMarkers = [];

    // Used only in Offshore App
    // noinspection JSUnusedGlobalSymbols
    this.removeBoatMarkers = function () {
        const routingResultsGroup = PWGMap.map1ItemLayerGroup;
        for (let r = 0; r < this._boatMarkers.length; r++) {
            pwMap.LayerGroup.removeItem(routingResultsGroup, this._boatMarkers[r]);
        }
        this._boatMarkers.splice(0);
    };

    this.updateBoatMarker = function (enabled, time, animating) {
        if (enabled !== undefined) {
            PWGMap.setBoatMarkersEnabled(!!enabled);
        }
        if (time === undefined) {
            if (this.slider) {
                time = this.slider.value;
            } else {
                time = $j('pw-map-slider').prop('value');
            }
            if (time === undefined) {
                console.log("Can't update boat marker for undefined time!");
                return;
            }
        }
        const mapSource = this.getMapSource(this.map1);
        let routeShown = true;
        // Animation follows boat.
        const animationFollowsBoat = window.app ?
            app.getSetting("Routing_AnimationFollowsBoat")  // Setting is defined in both PW and Offshore Apps.
            : $j('[name=animationFollowsBoat]').val() == "on";
        // Graphical warning markers are shown on map
        const warningsShownOnMap = window.app ?
            app.getSetting("Routing_WarningsShownOnMap", true)
            : $j('#warningsShownOnMapCheckbox').is(':checked');
        if (isOffshoreApp) {
            routeShown = app.getSetting('App_WeatherRoutingRouteShown', routeShown);
            const page = app.getSetting('App_CurrentPage');
            this.setRoutingPageName(PWOffshore.weatherRoutingPages[page]);
        }
        const isRoutingPage = this._routingPageName !== null;
        const isDestinationForecastPage = this._routingPageName === 'DestinationForecast';
        if (isRoutingPage) {
            const routingResultsGroup = PWGMap.map1ItemLayerGroup;
            if (routingResultsGroup && this.displayedRouteInfo) {
                const routeList = this.displayedRouteInfo.routeList;
                for (let r = 0; r < routeList.length; ++r) {
                    const pair = PWGMap.getRouteNodePair(r, time);
                    if (!pair) continue;
                    let t = pair.t;
                    let p = pwMap.utils.interpolate(pair.first.p, pair.second.p, pair.fract);
                    let routeDisplayInfo = this.getRouteDisplayInfo(r, mapSource);
                    let boatMarker = this._boatMarkers[r];
                    if (!boatMarker) {
                        boatMarker = this._boatMarkers[r] = pwMap.marker({
                            lat: p.lat,
                            lon: p.lon,
                            icon: routeDisplayInfo.icon,
                            zIndex: routeDisplayInfo.zIndex,
                            click: (function (r) {
                                return function () {
                                    PWGMap.slider?.setValue(this.latestTime);
                                    $j('pw-map-slider').prop('value', this.latestTime);
                                    PWGMap.setDisplayTime(this.latestTime);
                                    PWGMap.setActiveRoute(r);
                                };
                            })(r)
                        });
                        pwMap.LayerGroup.addItem(routingResultsGroup, boatMarker);
                        boatMarker.rotation = pair.first.bearing;
                    } else {
                        boatMarker.icon = routeDisplayInfo.icon;
                        boatMarker.zIndex = routeDisplayInfo.zIndex;
                        boatMarker.rotation += Atlas.modulateLon(pair.first.bearing - boatMarker.rotation) / (animating ? 2 : 1);
                        pwMap.Marker.setPosition(boatMarker, p);
                    }
                    if (r === this._routeIndex) {
                        if (PWGMap.getBoatMarkersEnabled() && routeShown && !isNaN(boatMarker.lat) && !isNaN(boatMarker.lon)) {
                            if (animating && animationFollowsBoat) {
                                window.PWGMap.map1._atlasMap.lat = boatMarker.lat;
                                window.PWGMap.map1._atlasMap.lon = boatMarker.lon;
                            }
                        }
                        if (isOffshoreApp) {
                            PWOffshore.setBoatPosition(boatMarker); // for updating grib selection
                        }
                    }
                    if (r < this.displayedRouteInfo.processedList.length) {
                        const polylines = this.displayedRouteInfo.processedList[r].polylines;
                        for (let p = 0; p < polylines.length; p++) {
                            if (polylines[p]) {
                                polylines[p].visible = routeDisplayInfo.visible;
                            }
                        }
                        const routeMarkers = this.displayedRouteInfo.processedList[r].routeMarkers;
                        for (let m = 0; m < routeMarkers.length; m++) {
                            if (routeMarkers[m]) {
                                routeMarkers[m].visible = routeDisplayInfo.visible;
                            }
                        }
                        const corridorPolygons = this.displayedRouteInfo.processedList[r].corridorPolygons;
                        for (let p = 0; p < corridorPolygons.length; p++) {
                            if (corridorPolygons[p]) {
                                corridorPolygons[p].visible = routeDisplayInfo.visible;
                            }
                        }
                        const showWarnings = warningsShownOnMap && routeDisplayInfo.visible && mapSource == getRouteSourceFromPath(routeList[r]);
                        this.displayedRouteInfo.processedList[r].warningMarkers?.forEach(marker => marker.visible = showWarnings);
                    }
                    boatMarker.latestTime = t;
                    pwMap.Marker.setVisible(boatMarker, routeDisplayInfo.visible && PWGMap.getBoatMarkersEnabled() && !isDestinationForecastPage);
                }
            }
        } else {
            for (let r = 0; r < this._boatMarkers.length; r++) {
                pwMap.Marker.setVisible(this._boatMarkers[r], false);
            }
        }
    };

    this.getRouteNodePair = function (r, t) {
        if (r === null) {
            return;
        }
        if (t === undefined) {
            t = this.slider?.value ?? $j('pw-map-slider').prop('value');
        }
        if (this.displayedRouteInfo) {
            const routeList = this.displayedRouteInfo.routeList;
            if (r >= 0 && r < routeList.length) {
                const path = routeList[r];
                const route = path.route;
                if (route && route.length > 0) {
                    let i = 0;
                    for (; i < route.length; i++) {
                        if (t < route[i].t) {
                            break;
                        }
                    }
                    const a = route[Math.max(0, Math.min(i - 1, route.length - 2))];
                    const b = route[Math.min(i, route.length - 1)];
                    const fract = (a.t < b.t) ? Math.min(1, Math.max(0, (t - a.t) / (b.t - a.t))) : 0;
                    return {first: a, second: b, fract: fract, path: path, t: t};
                }
            }
        }
        return null;
    };

    // FIXME we can use "image import" to locate these two assets:
    // should just be in STATIC_URL
    const boatMarkerStaticURL = window.ATLAS_STATIC_URL || window.STATIC_URL;
    this.boatIcon = pwMap.icon({
        img: boatMarkerStaticURL + 'images/boatmarker.png?202103',
        width: 32,
        height: 32,
        anchorX: 16,
        anchorY: 16
    });
    this.boatIconDimmed = pwMap.icon({
        img: boatMarkerStaticURL + 'images/boatmarker-dimmed.png?202103',
        width: 32,
        height: 32,
        anchorX: 16,
        anchorY: 16
    });
    this.boatIconDot = pwMap.icon({
        img: makeCircleImageDataUrl('rgba(255, 255, 255, 0.6)', 6),
        width: 12,
        height: 12,
        anchorX: 6,
        anchorY: 6
    });

    this.setBoatMarkersEnabled = function (boatMarkersEnabled) {
        this._boatMarkersEnabled = boatMarkersEnabled;
    };
    this.getBoatMarkersEnabled = function () {
        return this._boatMarkersEnabled;
    };

    this._routeIndex = null;
    this._routeSource = null;
    this._routeDepartureIndex = null;
    this._boatMarkersEnabled = true;
    this.resetCurrentRoute = function () {
        this._routeIndex = null;
        this._routeSource = null;
        this._routeDepartureIndex = null;
    };
    this.getRouteIndex = function () {
        return this._routeIndex;
    };
    this.setActiveRoute = function(routeIndex) {
        if (routeIndex === null || routeIndex === undefined) {
            this.resetCurrentRoute();
            return;
        }
        const routeList = this.displayedRouteInfo.routeList;
        const routeSource = getRouteSourceFromPath(routeList[routeIndex]);
        this._setRouteIndex(routeIndex);
        this._setRouteSource(routeSource);
    };
    this._getDepartureIndexForRoute = function(routeIndex) {
        if (_isValidRouteIndex(this.displayedRouteInfo.routeList, routeIndex)) {
            const path = this.displayedRouteInfo.routeList[routeIndex];
            return path.params && path.params.departureIndex || 0;  // TODO: 0 or null?
        }
        return 0; // TODO: 0 or null?
    };
    this._setRouteIndex = function (index) {
        const oldIndex = this._routeIndex;
        if (this.displayedRouteInfo && index !== null && index >= this.displayedRouteInfo.routeList.length) {
            this._routeIndex = null;
            this._routeDepartureIndex =  null;
        } else {
            this._routeIndex = index;
            this._routeDepartureIndex = this._getDepartureIndexForRoute(index);
            this.showRoute(index, true);
        }
        this.updateCorridorPolygonsForRouteIndex(oldIndex);
        this.updateCorridorPolygonsForRouteIndex(this._routeIndex);
        return this._routeIndex;
    };
    this._setRouteSource = function (routeSource) {
        if (this._routeSource !== routeSource) {
            this._routeSource = routeSource;
            const routeMapSource = this.getMapSourceForRouteSource(this._routeSource);
            if (routeMapSource) {
                this.setMapSource(this.map1, routeMapSource);
            }
        }
    };
    this.getMapSources = function (mapSource) {
        if (this.getMapSourcesCallback) {
            return this.getMapSourcesCallback(mapSource);
        }
        return [];
    };
    this.getRouteSources = function () {
        const routeSources = [];
        if (this.displayedRouteInfo && this._routingPageName) {
            const routeList = this.displayedRouteInfo.routeList;
            for (let r = 0; r < routeList.length; r++) {
                routeSources.push(getRouteSourceFromPath(routeList[r]))
            }
        }
        return routeSources;
    };
    this.getMapSourceForRouteSource = function (routeSource) {
        // mapSource === routeSource, if it exists
        if (this.getMapSources().indexOf(routeSource) !== -1) {
            return routeSource;
        }
        return null;
    };
    this.getMapSource = function (map) {
        return map.mapSourceForRenderingRoute ?? map.mapSource;
    };
    this.setMapSource = function (map, mapSource) {
        if (map.mapSource !== mapSource) {
            map.mapSource = mapSource;
            if (['PWG', 'PWE', 'ECMWF', 'GFS', 'UKMO', 'SPIRE', 'SPIREBETA'].includes(mapSource)) {
                map.mapSourceForRenderingRoute = mapSource;
            }
            if (this.mapSourceChangedCallback) {
                this.mapSourceChangedCallback(map, mapSource);
            }
            if (map === this.map1) {
                const routeMapSource = this.getMapSourceForRouteSource(this._routeSource);
                if (routeMapSource && routeMapSource !== mapSource) {
                    this.map1.closePopup();
                }
            }
            this.setCurrentRouteFromMapSource(this.getMapSource(map));
            this.updateBoatMarker();
        }
    };
    this.setCurrentRouteFromMapSource = function (mapSource) {
        if (!mapSource) {
            return;
        }
        if (this.displayedRouteInfo && this._routingPageName !== null) {
            const routeList = this.displayedRouteInfo.routeList;
            if (this._routeIndex !== null && this._routeIndex >= 0 && this._routeIndex < routeList.length) {
                let path = routeList[this._routeIndex];
                let routeSource = getRouteSourceFromPath(path);
                let routeMapSource = this.getMapSourceForRouteSource(routeSource);
                if (routeMapSource === mapSource) {
                    return; // already correct
                }
            }
            const candidates = [];
            for (let r = 0; r < routeList.length; r++) {
                const path = routeList[r];
                const routeSource = getRouteSourceFromPath(path);
                const routeMapSource = this.getMapSourceForRouteSource(routeSource);
                if (routeMapSource === mapSource && this._canAutomaticallyActivateRoute(r)) {
                    candidates.push(r);
                }
            }
            // prioritise same departureIndex
            for (const r of candidates) {
                if (this._getDepartureIndexForRoute(r) === this._routeDepartureIndex) {
                    this.setActiveRoute(r);
                    return;
                }
            }
            // fallback to first, if any
            if (candidates.length > 0) {
                this.setActiveRoute(candidates[0]);
                return;
            }
            // doesn't match a route, decide whether to reset the route
            if (['PWG', 'PWE', 'ECMWF', 'GFS', 'UKMO', 'SPIRE', 'SPIREBETA'].includes(mapSource)) {
                // mapSource matches a known route model, reset route.
                // e.g. Departure Planner ECMWF/GFS result, but PWE was selected
                this.resetCurrentRoute();
            } else {
                // no matching routes found, leave as is.
                // e.g. displaying currents
            }
        }
    };
    this.setCurrentRouteFromNode = function (node) {
        if (!node) {
            return;
        }
        if (this.displayedRouteInfo && this._routingPageName !== null) {
            this.slider?.setValue(node.t);
            $j('pw-map-slider').prop('value', node.t);
            this.setDisplayTime(node.t);

            const findNode = node.interpolatedA || node;
            const routeList = this.displayedRouteInfo.routeList;
            for (let r = 0; r < routeList.length; r++) {
                const path = routeList[r];
                for (let i = 0; i < path.route.length; i++) {
                    const n = path.route[i];
                    if (n === findNode) {
                        this.setActiveRoute(r);
                        return;
                    }
                }
            }
        }
    };

    this.boundaryEditor = new BoundaryEditor(this.map1);
    PWGMap_addInitListener(function () {
        PWGMap.boundaryEditor.initialise();
    });
    $j('.boundary-editor-close').on('click', function () {
        $j("#show-hide-table-buttons").show();
        $j('.routing-close-boundary-wrapper').hide();
        PWGMap.boundaryEditor.disable();
        $j('pw-waypoints').trigger('boundary-editor-close');
    });

    this.setDisplayTimeCheckValue = null;
    this.setDisplayTime = function (time, animating) {
        this.map1.setDisplayTime(time);
        this.updateBoatMarker(undefined, time, animating);
        if (this.setDisplayTimeCheckValue !== time) {
            this.setDisplayTimeCheckValue = time;
            this.showRouteNodePopupAtTime(time);
        }
    };
    this.updateCorridorPolygonsForRouteIndex = function (routeIndex) {
        if (routeIndex === null || routeIndex === undefined) {
            return;
        }
        if (this.displayedRouteInfo && routeIndex >= 0 && routeIndex < this.displayedRouteInfo.processedList.length) {
            const selected = this.getRouteIndex() == routeIndex;
            const routeColour = this.getRouteLineColour(this.displayedRouteInfo.routeList[routeIndex]);
            const corridorPolygons = this.displayedRouteInfo.processedList[routeIndex].corridorPolygons;
            for (let p = 0; p < corridorPolygons.length; p++) {
                const corridorPolygon = corridorPolygons[p];
                corridorPolygon.fillColour = selected ? routeColour : 'black';
                corridorPolygon.fillOpacity = selected ? 0.4 : 0.12;
            }
        }
    };
    this.showRouteToggles = function (show) {
        show = !!show || show === undefined;
        this.updateRouteToggles();
        $j('#route-toggles-dialog').toggle(show);
        $j('#toggle-routes-button').toggleClass('toggle-routes-button-selected', show);
    };
    this.updateRouteToggles = function () {
        const that = this;
        $j('.route-toggles-list-container').empty();

        const routeList = this.displayedRouteInfo && this.displayedRouteInfo.routeList || [];
        const isDeparturePlan = this.getRoutingMode() === 'TripPlanning';
        if (isDeparturePlan) {
            function toggleRoutesForDepartureIndex(departureIndex, show) {
                routeList.forEach((routeListItem, routeIndex) => {
                    if (routeListItem.params && routeListItem.params.departureIndex === departureIndex) {
                        that.showRoute(routeIndex, show);
                    }
                });
            }
            const departureVisible = this.displayedRouteInfo.departureVisible;
            for (let i = 0; i < 4; ++i) {
                const onChange = (function (i) {
                    return function () {
                        departureVisible[i] = !!this.checked;
                        toggleRoutesForDepartureIndex(i, !!this.checked);
                    };
                })(i);
                $j('.route-toggles-list-container').append(
                    $j('<input>').attr('id', 'route-toggle-' + i).addClass('checkbox').attr('type', 'checkbox')
                        .prop('checked', departureVisible[i])
                        .on('change', onChange),
                    $j('<label>').addClass('route-toggle-label').attr('for', 'route-toggle-' + i).text(gettext(`Departure ${i + 1}`)),
                    $j('<label>').addClass('route-toggle-hide').attr('for', 'route-toggle-' + i).text(`Hide`),
                    $j('<label>').addClass('route-toggle-show').attr('for', 'route-toggle-' + i).text(`Show`)
                );
            }
            $j('.route-toggles-list-container').toggle(true);
            $j('.route-toggles-list-empty').toggle(false);
            return;
        }

        if (isOffshoreApp) {
            const onChangeAll = function () {
                app.setSetting('App_WeatherRoutingRouteShown', this.checked);
                const processedList = this.displayedRouteInfo && this.displayedRouteInfo.processedList || [];
                if (processedList.length > 0) {
                    for (let i = 0; i < processedList.length; i++) {
                        that.showRoute(i, processedList[i].visible);
                    }
                }
            };
            $j('.route-toggles-list-container').append(
                $j('<input>').attr('id', 'route-toggle-all').addClass('checkbox').attr('type', 'checkbox')
                    .prop('checked', app.getSetting('App_WeatherRoutingRouteShown', true))
                    .on('change', onChangeAll),
                $j('<label>').addClass('route-toggle-label').attr('for', 'route-toggle-all').text('All Routes'),
                $j('<label>').addClass('route-toggle-hide').attr('for', 'route-toggle-all').text('Hide'),
                $j('<label>').addClass('route-toggle-show').attr('for', 'route-toggle-all').text('Show')
            );
        }
        if (routeList.length > 0) {
            const processedList = this.displayedRouteInfo.processedList;
            for (let i = 0; i < routeList.length; i++) {
                const routeListItem = routeList[i];
                const routeLabel = this.getRouteLabel(routeListItem);
                const routeColour = this.getRouteColour(routeListItem);
                const onChange = (function (i) {
                    return function () {
                        that.showRoute(i, this.checked);
                    };
                })(i);
                $j('.route-toggles-list-container').append(
                    $j('<input>').attr('id', 'route-toggle-' + i).addClass('checkbox').attr('type', 'checkbox')
                        .prop('checked', processedList[i].visible)
                        .on('change', onChange),
                    $j('<label>').addClass('route-toggle-label').attr('for', 'route-toggle-' + i).html(routeLabel).css('color', routeColour),
                    $j('<label>').addClass('route-toggle-hide').attr('for', 'route-toggle-' + i).text('Hide'),
                    $j('<label>').addClass('route-toggle-show').attr('for', 'route-toggle-' + i).text('Show')
                );
            }
            $j('.route-toggles-list-container').toggle(true);
            $j('.route-toggles-list-empty').toggle(false);
        } else {
            $j('.route-toggles-list-container').toggle(false);
            $j('.route-toggles-list-empty').toggle(true);
        }
    };
    function _isValidRouteIndex(routeList, routeIndex) {
        return typeof routeIndex === 'number' && routeIndex >= 0 && routeIndex < routeList.length;
    }
    this._canAutomaticallyActivateRoute = function(routeIndex){
        const routeList = this.displayedRouteInfo.routeList;
        if (!_isValidRouteIndex(routeList, routeIndex) || !this.displayedRouteInfo.processedList[routeIndex].visible) {
            return false;
        }
        if (this.getRoutingMode() === 'TripPlanning') {
            const mapSource = this.getMapSource(this.map1);
            const routeSource = getRouteSourceFromPath(routeList[routeIndex]);
            const routeMapSource = this.getMapSourceForRouteSource(routeSource);
            return mapSource !== null && mapSource === routeMapSource
        }
        return true;
    };
    this.showRoute = function (routeIndex, show = true) {
        const processedList = this.displayedRouteInfo?.processedList;
        if (processedList && routeIndex < processedList.length) {
            const routeList = this.displayedRouteInfo.routeList;
            const activeRouteWasVisible = _isValidRouteIndex(routeList, this._routeIndex) && processedList[this._routeIndex].visible;
            this.displayedRouteInfo.processedList[routeIndex].visible = show;
            let switchToRouteIndex = null;
            if (show) {
                // if active route is hidden, switch to this one
                if (!activeRouteWasVisible && this._canAutomaticallyActivateRoute(routeIndex)) {
                    switchToRouteIndex = routeIndex;
                }
            } else {
                // hiding active route, switch to another
                if (routeIndex === this._routeIndex) {
                    for (let r = 0; r < this.displayedRouteInfo.processedList.length; r++) {
                        if (this._canAutomaticallyActivateRoute(r)) {
                            switchToRouteIndex = r;
                            break;
                        }
                    }
                }
            }
            if (switchToRouteIndex !== null) {
                this.setActiveRoute(switchToRouteIndex);
            }
            this.updateBoatMarker();
            this.updateRouteToggles();
        }
    };
    this.plotRoutes = function (routeList, formatting, hideWeatherMarkers, maxTime, minTime, maxSectionTime) {
        const that = this;
        this.displayedRouteInfo = {
            routeList: routeList,
            formatting: formatting,
            processedList: [],
            departureVisible: [true, true, true, true],
            popupTemporarilyClosed: false
        };
        const mapSource = this.getMapSource(this.map1);
        for (let routeIndex = 0; routeIndex < routeList.length && routeIndex < formatting.length; routeIndex++) {
            makeBoatMarkerIcon(formatting[routeIndex]);
            const showMarkers = !!formatting[routeIndex].showMarkers;

            const nodes = []; // nodes for markers
            const nodePositions = [];
            const route = routeList[routeIndex].route;
            const polylinesCoords = polylineCoordsFromRoute(route, minTime, maxTime, maxSectionTime, nodes, nodePositions);
            this.displayedRouteInfo.processedList[routeIndex] = {
                nodes: nodes,
                nodePositions: nodePositions,
                polylines: [],
                corridorPolygons: [],
                routeMarkers: [],
                visible: true
            };
            const routeDisplayInfo = this.getRouteDisplayInfo(routeIndex, mapSource); // must be called only after processedList is setup
            const baseMarkerOptions = {
                icon: routeIcons[formatting[routeIndex].color],
                clickable: true,
                draggable: false,
                title: '',
                raiseOnDrag: false,
                zIndex: pwMap.zLevels.routeSample
            };
            const markers = this.displayedRouteInfo.processedList[routeIndex].routeMarkers;
            const group = formatting[routeIndex].group;
            if (!hideWeatherMarkers && showMarkers) {
                for (let i = 0; i < nodes.length; i++) {
                    const markerOptions = {...baseMarkerOptions};
                    markerOptions.position = nodePositions[i];
                    if (formatting[routeIndex].endsWithCross && i == nodes.length - 1) {
                        markerOptions.icon = noDataIcon;
                    }
                    markerOptions.click = (function (node, formatting) {
                        return function () {
                            showWindSampleOnMap(node, formatting);
                        };
                    })(nodes[i], formatting[routeIndex]);
                    const marker = pwMap.marker(markerOptions);
                    if (group) {
                        pwMap.LayerGroup.addItem(group, marker);
                    } else {
                        this.addItem(marker);
                    }
                    markers.push(marker);
                }
            }
            if (routeList[routeIndex].corridor) {
                const corridorPolygons = this.displayedRouteInfo.processedList[routeIndex].corridorPolygons;
                const corridor = routeList[routeIndex].corridor;
                const CORRIDORS_ENABLED = false;
                if (CORRIDORS_ENABLED && corridor.length > 0) {
                    const corridorPolygon = pwMap.polygon({
                        path: corridor[0],
                        fillColour: 'black',
                        fillOpacity: 0.12,
                        lineOpacity: 0,
                        lineWeight: 0
                    });
                    corridorPolygons.push(corridorPolygon);
                    this.plotRouteOverlays.push(corridorPolygon);
                    if (group) {
                        pwMap.LayerGroup.addItem(group, corridorPolygon);
                    } else {
                        this.addItem(corridorPolygon);
                    }
                    this.updateCorridorPolygonsForRouteIndex(routeIndex);
                }
            }
            // Unclear where this step should sit, or what the zIndex should be!
            const [popup, ...sourceWarningMarkers] = warningItemsFromRoute(route, group, ROUTING_INVALID_VALUE, routeIndex);
            this.displayedRouteInfo.processedList[routeIndex].warningMarkers = sourceWarningMarkers;

            for (const marker of sourceWarningMarkers ) {
                this.plotRouteOverlays.push(marker);
                this.displayedRouteInfo.processedList[routeIndex].routeMarkers.push(marker);
                marker.visible = (mapSource == getRouteSourceFromPath(routeList[routeIndex]));
            }
            for (let p = 0; p < polylinesCoords.length; p++) {
                let polylineClick = null;
                if (!showMarkers) {
                    polylineClick = (function (nodes, routeIndex) {
                        return function (position, pathIndex) {
                            that._setRouteIndex(routeIndex);
                            const pi = Math.floor(pathIndex);
                            if (pi >= 0 && pi < nodes.length - 1) {
                                const t = pathIndex - pi;
                                const node = interpolateNodes(nodes[pi], nodes[pi + 1], t);
                                showWindSampleOnMap(node, formatting);
                            }
                            $j(popup.element).trigger('click'); // hide popup and associated path highlighting if visible
                        };
                    })(nodes, routeIndex);
                }
                const polyline = polylineWithFormat(polylinesCoords[p], formatting[routeIndex], pwMap.zLevels.routePath, group, polylineClick);
                if (polyline) {
                    this.displayedRouteInfo.processedList[routeIndex].polylines[p] = polyline;
                    this.plotRouteOverlays.push(polyline);
                    this.plotRouteOverlayPolylines.push(polyline);
                    polyline.visible = routeDisplayInfo.visible;
                }
            }
            for (let i = 0; i < markers.length; i++) {
                markers[i].visible = routeDisplayInfo.visible;
                this.plotRouteOverlays.push(markers[i]);
            }
        }
        this.updateRouteToggles();
    };
    this.showBlogPostOnMap = function (post, optFormatting, expandBlogPost) {
        console.log('showBlogPostOnMap()');
        console.log(post);
        console.log(optFormatting);
        const node = post.sample;

        const sampleHtml = getWindSampleHtml(node, optFormatting);
        if (!sampleHtml) {
            PWGMap.map1.closePopup();
            return;
        }
        const point = node.p?.lat !== undefined ? node.p : null;

        let fontSize = '0.75rem';
        let patchMarginRight = '0.5rem';
        let nameMarginBottom = '0.25rem';
        let deviceFontSize = '0.625rem';
        if (isOffshoreApp) {
            fontSize = 12 / 96 + 'in';
            patchMarginRight = 8 / 96 + 'in';
            nameMarginBottom = 4 / 96 + 'in';
            deviceFontSize = 10 / 96 + 'in';
        }
        let dialogHtml = '<div  class="blog-location-click" style="white-space:nowrap; font-size:' + fontSize + '; position:relative">';
        let messageStyle;

        if (post.uniqueName) {
            messageStyle = {
                'border-left-style': 'solid',
                'border-left-width': 'thick',
                'border-left-color': PWGMap.boatColour(post.uniqueName)
            }
        }
        //dialogHtml += '<div class="blog-location-click">';
        dialogHtml += $j('<div>', {"class": "blog-location-icon"}).css({
            'float': 'left',
            'margin-right': patchMarginRight
        })[0].outerHTML;
        //dialogHtml += '<b style="display:inline-block; margin-bottom:' + nameMarginBottom + '">' + optFormatting.name + '</b><br style="clear:both;">';
        //dialogHtml += '<b style="display:block; margin-bottom:' + nameMarginBottom + '">' + post.title + '</b><br style="clear:both;">';
        dialogHtml += $j('<b>')
            .css({
                'display': 'block',
                'margin-bottom': nameMarginBottom,
                'margin-right': '0.2in',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            }).html(post.title)[0].outerHTML;
            dialogHtml += '<br style="clear:both;">';
        //dialogHtml += '</div>';

        // FIXME all the following code is Atlas specific but I think we're going to need Google Maps compatibility back
        // for the Cruising Rally page.
        // PWGMap.map1.openPopup(point, dialogHtml, optFooter, messageStyle);
        const elem = PWGMap.map1.openPopup(point, '');
        render(html`${unsafeHTML(dialogHtml)} ${sampleHtml}`, elem);

        // HACK: I think using jQuery event handler here would lead to a memory leak?
        // ANOTHER HACK: Google Maps API somehow adds the popup to the document asynchronously, so wait a tiny amount of time.
        if (expandBlogPost) {
            setTimeout(function () {
                $j('.blog-location-click').each(function () {
                    this.addEventListener('click', function () {
                        $j('#' + PWGMap.makeIdForBlogPost(post)).find('.blog-post-wrapper').each(expandBlogPost);
                    }, true);
                });
            }, 10);
        }


        //if (PWGMap.showWindSampleOnMapCallback) {
        //    PWGMap.showWindSampleOnMapCallback(node);
        //}

    };
    this.plotBlogPostMarkers = function (posts, formatting) {
        // FIXME does not work at all yet
        return;
        makeBoatMarkerIcon(formatting);
        const baseMarkerOptions = {
            icon: routeIcons[formatting.color],
            clickable: true,
            draggable: false,
            title: '',
            raiseOnDrag: false,
            zIndex: pwMap.zLevels.routeSample,
            fillColour: formatting.fillColour,
            colour: formatting.colour,
            anchorX: 6,
            anchorY: 6,
            width: 12,
            height: 12
        };
        const group = formatting.group;
        const boatColour = formatting.boatColour;
        posts.forEach(function (post) {
            if (post.sample) {
                const markerOpts = {
                    lat: post.sample.p.lat,
                    lon: post.sample.p.lon,
                    fillColour: boatColour,
                    // click: function boatMarkerClicked() {
                    //     PWGMap.showWindSampleOnMap(sample, sampleFormat);
                    // },
                    zIndex: pwMap.zLevels.routePosition,
                    anchorX: 6,
                    anchorY: 6,
                    width: 12,
                    height: 12
                };
                const returnOpts = {};
                const circleMarker = pwMap.circleMarker(markerOpts, undefined, returnOpts);
                if (returnOpts.hidden) {
                    pwMap.LayerGroup.removeItem(group, circleMarker);
                    //group.boatMarker = null;
                } else {
                    pwMap.LayerGroup.addItem(group, circleMarker);
                    //group.boatMarker = circleMarker;
                }

                // Do we need to do this??
                //PWGMap.plotRouteOverlays.push(marker);
            }
        });
    };

    this.polylineCoordsFromRoute = polylineCoordsFromRoute;
    this.polylineWithFormat = polylineWithFormat;
    this.clearRoutes = function clearRoutes() {
        if (this.plotRouteOverlays !== undefined) {
            for (let i = 0; i < this.plotRouteOverlays.length; i++) {
                this.removeItem(this.plotRouteOverlays[i]);
            }
        }
        this.plotRouteOverlays = [];
        this.plotRouteOverlayPolylines = [];
    };
    this.routerWeatherSourceChanged = function (newValue) {
        this.weatherSource = newValue;
    };

    this.getSourceLongLabel = function (path) {
        let srcLabel = this.getRouteLabel(path);
        const srcColour = this.getRouteColour(path);
        if (srcLabel !== '') {
            srcLabel = '<span style="color:' + srcColour + '">' + srcLabel + '</span>';
        }
        return srcLabel;
    };

    this.boatColour = function boatColour(boatName) {
        const hash = stringHash(boatName.toLowerCase());
        const rng = new RNG(hash);
        const h = (rng.nextFloat() * 123) % 1;
        const s = 0.6 + rng.nextFloat() * 0.4;
        const v = 0.8 + rng.nextFloat() * 0.2;
        return hsvToRgb(h, s, v);
    };

    function RNG(seed) {
        // LCG using GCC's constants
        this.m = 0x100000000; // 2**32;
        this.a = 1103515245;
        this.c = 12345;
        this.state = seed ? seed : Math.floor(Math.random() * (this.m - 1));
    }

    RNG.prototype.nextInt = function () {
        this.state = (this.a * this.state + this.c) % this.m;
        return this.state;
    };
    RNG.prototype.nextFloat = function () {
        return this.nextInt() / (this.m - 1); // range [0,1]
    };

    function stringHash(str) {
        let hash = 0,
            i,
            ch;
        if (!str.length) {
            return hash;
        }
        for (i = 0; i < str.length; i++) {
            ch = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + ch;
            hash = hash & hash; // Convert to 32bit
        }
        return Math.abs(hash);
    }

    function hsvToRgb(h, s, v) {
        let r,
            g,
            b;
        const i = Math.floor(h * 6);
        const f = h * 6 - i;
        const p = v * (1 - s);
        const q = v * (1 - f * s);
        const t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0:
                r = v; g = t; b = p;
                break;
            case 1:
                r = q; g = v; b = p;
                break;
            case 2:
                r = p; g = v; b = t;
                break;
            case 3:
                r = p; g = q; b = v;
                break;
            case 4:
                r = t; g = p; b = v;
                break;
            case 5:
                r = v; g = p; b = q;
                break;
        }
        return 'rgb(' + parseInt(r * 255) + ',' + parseInt(g * 255) + ',' + parseInt(b * 255) + ')';
    }

    this.setupLegend = function setupLegend(formats) {
        // `formats` argument may be an array of format objects or a plain object containing format objects.
        if (Array.isArray(formats)) {
            formats = formats.slice();
        } else {
            formats = $j.map(formats, function (val) {
                return val;
            });
        }
        // #8730 Specifically for YellowBrick multi-leg races, we'll have redundant entries.
        // We need to use the last one by field 'lastUpdated' (or in order of appearance otherwise)
        // to get the most applicable clickHandler, but we take the opportunity to sort first by name.
        // Doing this should be safe for any scenario - there's never a point in showing
        // the same name and colour twice.
        // #9094 Use an X symbol for boats where data is more than 12 hours old
        formats.sort(function (a, b) {
            // Sort by name, then by last updated
            return a.name.localeCompare(b.name) || ((a.lastUpdate || 0) - (b.lastUpdate || 0));
        });
        const deduped_formats = {};
        formats.forEach(function (format) {
            deduped_formats[(format.name + '!!' + format.color)] = format;
        });
        const timestamp_cutoff = Date.now() / 1000 - 12 * 3600;
        // Replace legend HTML
        const forecastLegendHead = $j('#forecastLegendHead').empty();
        $j.each(deduped_formats, function (key, format) {
            const outdated = 'lastUpdate' in format && (format.lastUpdate || 0) < timestamp_cutoff;
            const el = outdated ? makeLegendRow(
                "color:" + format.color,
                format.name,
                'x-symbol'
            ) : makeLegendRow(
                "background-color:" + format.color,
                format.name,
                'circle');
            if (format.clickHandler) {
                $j('.boat-name', el)
                    .on('click', format.clickHandler)
                    .css('cursor', 'pointer');
            }
            forecastLegendHead.append(el);
        });
        $j('.map-legend-wrapper').removeClass('display-none');
    };

    this.displayBlogPostSnippets = function (posts, handlers, keepFeed) {
        const column = $j('.track-display-right-column');
        const feedClass = 'blog-post-feed';
        if (!keepFeed) {
            column.children('.' + feedClass).remove();
        }
        posts.forEach(function renderBlogPostSnippet(post) {
            const postClass = post.special ? 'blog-post-' + post.special : feedClass;
            const postId = PWGMap.makeIdForBlogPost(post);
            $j('#' + postId).remove();
            const el = $j('<div>', {
                "class": "blog-post-outer-wrapper " + postClass,
                id: postId
            });
            // I need to make sure img elements in the preview elements do not load because they add up to a LOT of data.
            // To achieve this I have to modify the HTML source BEFORE parsing
            // it with jQuery/the browser because parsing the source creates img elements and immediately
            // kicks off the request for the img src! So it's time to parse HTML with regular
            // expressions, always a great idea!
            // FIXME adding attribute loading="lazy" is supported from Chromium 77, and is a better solution...
            // let post_html = post['cooked'].replace(/<img\s/ig, '<img loading="lazy" ');
            // but for now we need something hackier
            // Note that inverting this transform is more complicated than you'd think, but we can instead
            // use post['cooked'] again when we need the img elements to work.
            let post_html = post['cooked'].replace(/<img\s/ig, '<img-placeholder ');
            el.append(
                $j('<div>', {"class": "blog-post-wrapper"})
                    .css(post.uniqueName ? {
                        borderLeft: "thick solid " + PWGMap.boatColour(post.uniqueName)
                    } : {})
                    .append(
                        $j('<span>', {"class": "blog-post-expand"}),
                        (post.name ? $j('<span>', {"class": "blog-post-boat-name"}).text(post.name) : []),
                        $j('<article>', {"class": "blog-post cooked"})
                            .data(post) // All post details left in element data for event handlers
                            .append(
                                $j('<h1>')
                                    .html(post['title']),
                                $j('<p>', {"class": "track-display-blog-post-date"})
                                    .text(post['created_at'] ? new Date(post['created_at']).toString() : ""),
                                $j('<div>', {"class": "track-display-blog-post-body"})
                                    .html(post_html)
                            )
                    )
                    .on(
                        typeof handlers === 'function' ? {click: handlers} : handlers
                    )
                // Note the expand control is just visual, the whole .blog-post-wrapper is a target
            );
            // This is a bit hacky... we might use flex CSS to order blog posts but this is a workable default
            if (post.special) {
                el.insertBefore('.blog-post-outer-wrapper.blog-post-add');
            } else {
                el.appendTo(column);
            }
        });
        column.removeClass('display-none');
    };
    this.makeIdPrefixForRegistrationId = function (registrationId) {
        return 'blog-' + registrationId;
    };
    this.makeIdForBlogPost = function (post) {
        // returns undefined if the post data has no topic_id and no uuid.
        const prefix = this.makeIdPrefixForRegistrationId(post.registrationId);
        let postId;
        if (post.topic_id) {
            postId = prefix + '-post-topic-' + post.topic_id;
        } else if (post.uuid) {
            postId = prefix + '-post-uuid-' + post.uuid;
        }
        return postId;
    }
}

function graphResults(routeList, formatting, waveFilter) {
    render(
        [
            html`<br>`,
            routeList.map((route, index) =>
                html`
                  <table class=routingGraphLegend>
                    <tr>
                      <td style=${styleMap({backgroundColor: formatting[index].color, width: "30px"})}>
                      <td>${PWGMap.getRouteLabel(route)}
                  </table>
                `),
            html`
              <pw-routing-graphs .routeList=${routeList}
                                 .formatting=${formatting}
                                 .waveFilter=${waveFilter}>
            `
        ],
        $j('#graphedResultsContent')[0]
    );
}

function canShowCurrents(routeList) {
    return routeList.some(routeListItem => routeListItem.params?.showCurrents);
}

function makeLegendRow(legendStyle, text, legendClass) {
    legendClass = legendClass || 'legendBlock';
    const tr = document.createElement('tr');
    $j(tr).addClass("key");
    const td1 = document.createElement('td');
    const td2 = document.createElement('td');
    tr.appendChild(td1);
    tr.appendChild(td2);
    td1.innerHTML = "<span class='" + legendClass + "' style='" + legendStyle + "'></span>";
    td2.style.whiteSpace = 'nowrap';
    $j('<span>').addClass("boat-name").text(text).css('color', '#555').appendTo(td2);
    return tr;
}

$j(function () {
    $j('#tracking-settings-form').on('submit', function (e) {
        e.preventDefault();
        $j.ajax({
            url: $j('#tracking-settings-form')[0].action,
            type: 'post',
            data: {
                'track_duration': $j('#tracking-settings-form select').val()
            },
            success: function () {
                location.reload();
            }
        })
    });
});

function powerSettingsPreferenceConversion(fieldName, fieldValue, deconvert=false) {
    let parsed = Number(fieldValue), to, from;

    if (fieldName == "significantHeight") {
        from = deconvert ? preference_units.wave_height : units.metres;
        to = deconvert ? units.metres : preference_units.wave_height;
    } else if (fieldName == "economicBoatSpeed") {
        from = deconvert ? preference_units.wind_speed : units.knots;
        to = deconvert ? units.knots : preference_units.wind_speed;
    } else if (fieldName == "economicFuelConsumption") {
        from = deconvert ? preference_units.fuel_volume : units.litres;
        to = deconvert ? units.litres : preference_units.fuel_volume;
    } else {
        return fieldValue;
    }

    let converted = convertAndFormat(parsed, from, to, to.format);
    return converted;
}
