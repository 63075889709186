// noinspection CssUnusedSymbol

import {css, html, LitElement, nothing} from "lit";
import {classMap} from "lit/directives/class-map.js";
import {styleMap} from "lit/directives/style-map.js";
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {isOffshoreApp} from "../../platform.js";
import {gettext, interpolate, pgettext} from "../lit-directives.js";
import {
    format1dp,
    format2dp,
    formatRounded,
    formatTimeDifference,
    formatTimestamp,
    ROUTING_INVALID_VALUE,
    shouldShowFuelConsumption
} from "../routing-utils.js";
import {addWarningsToRoute, WARNINGS} from "../routingWeatherWarnings.js";
import {GET_FIELD_UNIT} from '../convertable.js';
import {convertAndFormat, formats, getUnitLabel, getUnitSymbol, units} from '../convertable-units.js';
import {getFormattedSummary, getFormattedTableNode, getUserNode, routing_units} from '../routing-units.js';
import {preference_units} from '../preference-units.js';
import {locationGmdssWarnings, makeGmdssRequest} from "../gmdssGlobal.js";

const dash = html`&nbsp;&mdash;&nbsp;`;

const ngettext = window.ngettext;

export const ROUTE_COLOURS = {PWG: '#0924fa', PWE: '#fc0b1b', GFS: '#0e8011', ECMWF: '#000000'};


function interpolateUnitLabel(fmt, unit) {
    return interpolate(fmt, {unit: getUnitLabel(unit)}, true);
}

function interpolateUnitSymbol(fmt, unit) {
    return interpolate(fmt, {unit: getUnitSymbol(unit)}, true);
}

let gmdssDataPromise;
if (!isOffshoreApp) {
    gmdssDataPromise = makeGmdssRequest();
}

// Front load translatable strings because our l18n tech doesn't understand backtick syntax in JS, therefore it may not see
// the strings to include them in the translation source .po file if they appear later on.
// (It's inconsistent that we use two objects and the rest are just constants but that doesn't matter much.)

// text that already contains units (knots, mm/hr, kJ/kg, days, NM)
const
    interpolation_text_will_motor_when_knots = gettext('Will motor at %s knots when boat speed is less than %s knots.'),
    text_avg_wind_speed = gettext('Avg Wind Speed (%(unit)s)'),
    text_max_gust = gettext('Max Gust (%(unit)s)'),
    text_max_rain = gettext('Max Rain (%(unit)s)'),
    text_max_wind_speed = gettext('Max Wind Speed (%(unit)s)'),
    text_min_wind_speed = gettext('Min Wind Speed (%(unit)s)'),
    text_max_cape = gettext('Max CAPE (%(unit)s)'),
    text_passage_time_days = gettext('Passage Time (days)'),
    text_motoring_time_days = gettext('Motoring Time (days)');

const
    text_timezone = gettext("Timezone"),
    text_start_time = gettext("Start Time"),
    text_end_time = gettext("Finish Time"),
    text_time_taken = gettext("Time Taken"),
    text_motoring_time = gettext("Motoring Time"),
    text_distance_travelled = gettext("Distance Traveled"),
    text_fuel_consumption_unit = gettext("Fuel Consumption (%(unit)s)"),
    text_fuel_consumption_unit_short = gettext("Fuel (%(unit)s)"),
    text_average_speed = gettext("Average Speed (%(unit)s)"),
    text_time = gettext("Time"),
    text_tws = pgettext("true wind speed", "TWS"),
    text_gust = gettext("Gust"),
    text_twd = pgettext("true wind direction", "TWD"),
    text_twa = pgettext("true wind angle", "TWA"),
    text_bsp = pgettext("boat speed over ground", "SOG"),
    text_heading = pgettext("heading/course over ground", "COG"),
    text_warnings = gettext("Warnings"),
    interpolation_text_departure_n = gettext("Departure %s"),
    text_advanced = gettext('Advanced'),
    text_CAPE = gettext('CAPE'),
    text_average_results = gettext("These results are an average of all four weather models."),
    text_convention_for_current_direction = gettext("Note: The convention for Current direction is opposite to that of Wind Direction. e.g. A North Current flows from the South to the North."),
    text_depth = gettext('Depth'),
    text_direction = gettext('Direction'),
    text_downwind = gettext('Downwind'),
    text_effects_of_currents = gettext("The algorithm includes the effects of currents on the boat's SOG (speed over ground), COG (course over ground) and wind speed/direction."),
    text_false = gettext('False'),
    text_fog = gettext('Fog'),
    text_latitude = gettext('Latitude'),
    text_lightning = gettext('Lightning'),
    text_longitude = gettext('Longitude'),
    text_no_data_dash = gettext("If there is no current data available, a \"&mdash;\" is displayed. This is not slack water."),
    text_note_TWA_displayed_as_VMG = gettext("When 'VMG' is displayed in the TWA column it means take the best upwind VMG course, with tacks to keep close to the optimal route."),
    text_optional_ocean_currents = gettext("This table displays tidal &amp; ocean currents if you have this option selected in the routing preferences. The route displayed is optimised for fastest time using current &amp; wind data."),
    text_pressure = gettext('Pressure'),
    text_processing_time = gettext("Processing Time"),
    text_rain = gettext('Rain'),
    text_speed = gettext('Speed'),
    text_temperature = gettext('Temperature'),
    text_comfort = gettext("Comfort"),
    // Translators: xgettext:no-c-format
    text_time_1m_to_2m_wave = gettext('% Time 1&ndash;2m Wave'),
    // Translators: xgettext:no-c-format
    text_time_20_to_30_knots = gettext('% Time 20&ndash;30 Knots'),
    // Translators: xgettext:no-c-format
    text_time_2m_to_3m_wave = gettext('% Time 2&ndash;3m Wave'),
    // Translators: xgettext:no-c-format
    text_time_30_to_40_knots = gettext('% Time 30&ndash;40 Knots'),
    // Translators: xgettext:no-c-format
    text_time_3m_to_4m_wave = gettext('% Time 3&ndash;4m Wave'),
    // Translators: xgettext:no-c-format
    text_time_4m_to_5m_wave = gettext('% Time 4&ndash;5m Wave'),
    // Translators: xgettext:no-c-format
    text_time_8_to_20_knots = gettext('% Time 8&ndash;20 Knots'),
    // Translators: xgettext:no-c-format
    text_time_downwind = gettext('% Time Downwind'),
    // Translators: xgettext:no-c-format
    text_time_gt_100_polar_due_to_waves = gettext('% Time &gt; 100% Polar due to Influence of Waves'),
    // Translators: xgettext:no-c-format
    text_time_gt_40_knots = gettext('% Time &gt; 40 Knots'),
    // Translators: xgettext:no-c-format
    text_time_gt_5m_wave = gettext('% Time &gt; 5m Wave'),
    // Translators: xgettext:no-c-format
    text_time_high_slamming_inc = gettext('% Time &gt; 50% Slamming Inc'),
    // Translators: xgettext:no-c-format
    text_time_high_slamming_inc_note = gettext('50% or greater may lead to hull damage or crew injuries'),
    // Translators: xgettext:no-c-format
    text_time_high_vertical_acc = gettext('% Time &gt; 0.2g\'s Vertical Acc'),
    text_time_high_vertical_acc_note = gettext('Dangerous to perform tasks and worsening seasickness'),
    // Translators: xgettext:no-c-format
    text_time_low_slamming_inc = gettext('% Time &lt; 50% Slamming Inc'),
    // Translators: xgettext:no-c-format
    text_time_low_vertical_acc = gettext('% Time &lt; 0.2g\'s Vertical Acc'),
    text_time_low_vertical_acc_note = gettext('Can safely perform tasks and avoid seasickness'),
    // Translators: xgettext:no-c-format
    text_time_lt_100_polar_due_to_waves = gettext('% Time &lt; 100% Polar due to Influence of Waves'),
    // Translators: xgettext:no-c-format
    text_time_lt_1m_wave = gettext('% Time &lt; 1m Wave'),
    // Translators: xgettext:no-c-format
    text_time_lt_4deg_roll = gettext('% Time &lt; 4 deg Roll'),
    text_time_lt_4deg_roll_note = gettext('Can move around boat and complete tasks safely'),
    // Translators: xgettext:no-c-format
    text_time_lt_8_knots = gettext('% Time &lt; 8 Knots'),
    // Translators: xgettext:no-c-format
    text_time_reaching = gettext('% Time Reaching'),
    text_time_reaching_power = gettext('% Time On the beam'),
    // Translators: xgettext:no-c-format
    text_time_roll_over_4deg = gettext('% Time &gt; 4 deg Roll'),
    text_time_roll_over_4deg_note = gettext('Movement around boat is difficult and unsafe'),
    // Translators: xgettext:no-c-format
    text_time_upwind = gettext('% Time Upwind'),
    // Translators: xgettext:no-c-format
    text_time_upwind_gt_15_knots = gettext('% Time Upwind &gt; 15 Knots'),
    // Translators: xgettext:no-c-format
    text_time_wind_against_current = gettext('% Time Wind Against Current'),
    text_time_wind_against_current_note = gettext('Current &ge; 1 Knot & Wind &ge; 12 Knots'),
    text_upwind = gettext('Upwind'),
    text_time_missing_wave = gettext('% Time Missing Wave Data'),
    text_optimise_for = gettext('Optimise for'),
    text_reaching = gettext('Reaching'),
    text_on_the_beam = gettext('On the beam'),
    text_avoid_wind_speeds = gettext('Avoid Wind Speeds'),
    text_avoid_wave_heights = gettext('Avoid Wave Heights');

const
    text_upwind_50_deg_colon = gettext('Upwind 50&deg;:'),
    text_downwind_160_deg_colon = gettext('Downwind 160&deg;:'),
    text_upwind_colon = gettext('Upwind:'),
    text_downwind_VMG_colon = gettext('Downwind VMG:'),
    text_maximum_speed_no_wind_colon = gettext('Maximum speed in zero knot wind:'),
    text_significant_height = gettext('Height:'),
    text_eco_bsp = gettext('Eco Boat Speed:'),
    text_eco_rpm = gettext('Eco RPM:'),
    text_eco_fuel_rate = gettext('Eco Fuel Consumption:');


function gettext_for_days(days) {
    return interpolate(ngettext('%s day', '%s days', days), [days]);
}

function isset(x, ignoreValue) {
    if (typeof ignoreValue == 'undefined') {
        ignoreValue = null;
    }
    return typeof x != 'undefined' && x !== null && x !== ignoreValue;
}

function getDepartureCount(path) {
    return path.params && path.params.departureCount || 1;
}

function formatTimezone(utcOffset) {
    const h = Math.floor(Math.abs(utcOffset));
    const m = Math.floor(Math.abs(utcOffset) * 60) % 60;
    return [
        pgettext("UTC timezone", "GMT"),
        (utcOffset >= 0 ? '+' : '-'),
        (h < 10 ? '0' : '') + h,
        ':',
        (m < 10 ? '0' : '') + m
    ];
}

function routeSummary(route, params) {
    let result = {
        startTime: '—', endTime: '—', timeTaken: '—', distanceTravelled: '—', averageSpeed: '—', timeZone: '—'
    };
    if (route && route.length) {
        const firstNode = route[0];
        const lastNode = route[route.length - 1];
        const value = {
            startTime: firstNode.t,
            endTime: lastNode.t,
            timeTaken: lastNode.t - firstNode.t,
            fuelConsumed: params.useFuelOptimizer ? lastNode.accumfuelconsumed : null
        };
        if (typeof lastNode.d !== 'undefined') {
            const h = (lastNode.t - firstNode.t) / 3600;
            const nm = lastNode.d / 1.852;
            const knots = nm / h;
            value.distanceTravelled = nm;
            value.averageSpeed = knots;
        }
        result = getFormattedSummary(value);
        result.timeZone = formatTimezone(PWGMap.utcOffset);
    }
    return result;
}

function formatHoursToDays2dp(n) {
    if (n === null || isNaN(n)) return n;
    return format2dp(n / 24);
}

function formatPercentage(n) {
    if (n === null || isNaN(n)) return n;
    if (!n) return '';
    return Math.round(n * 100) + '%';
}

function summarisedDepartureStats(tripStats) {
    const departureSummaryStats = {};
    const departureSummaryStatsCount = {};
    for (const stats of tripStats) {
        const key = `${stats.path.params.departureIndex + 1}`;
        if (!departureSummaryStats[key]) {
            departureSummaryStats[key] = {};
            departureSummaryStatsCount[key] = {};
        }
        const summaryStats = departureSummaryStats[key];
        const summaryStatsCount = departureSummaryStatsCount[key];
        for (const field of Object.keys(stats)) {
            if (typeof stats[field] === 'number') {
                if (!(field in summaryStats)) {
                    summaryStats[field] = stats[field];
                } else if (typeof stats[field] === 'number') {
                    if (field.indexOf('min') === 0) {
                        summaryStats[field] = Math.min(summaryStats[field], stats[field]);
                    } else if (field.indexOf('max') === 0) {
                        summaryStats[field] = Math.max(summaryStats[field], stats[field]);
                    } else {
                        summaryStats[field] += stats[field];
                        summaryStatsCount[field] = (summaryStatsCount[field] || 1) + 1;
                    }
                }
            } else {
                if (!(field in summaryStats)) {
                    summaryStats[field] = stats[field];
                }
            }
        }
    }
    let departureStatsList = [];
    for (let i = 0; i < 4; i++) {
        const key = `${i + 1}`;
        const summaryStats = departureSummaryStats[key];
        const summaryStatsCount = departureSummaryStatsCount[key];
        for (const field in summaryStatsCount) {
            summaryStats[field] /= summaryStatsCount[field];
        }
        summaryStats.src = '';
        summaryStats.labelHtml = '&nbsp;';
        departureStatsList.push(summaryStats);
    }
    return departureStatsList;
}


function grey(measure, opacity = 1.0) {
    // The idea here is to get a colour that looks like rgba(26, 47, 67, measure)
    // as if using opacity = measure when blending into an opaque white background.
    // Then we tack on the opacity value.
    // No range checking, sane measure and opacity  values are from 0 to 1 and that's on you, dear caller.
    const r = 255 - measure * (255 - 26),
        g = 255 - measure * (255 - 47),
        b = 255 - measure * (255 - 67);
    return css`rgba(${r} ${g} ${b} / ${opacity})`;
}

function getSourceLongLabel(path) {
    let srcLabel = getRouteLabel(path);
    const srcColour = getRouteColour(path);
    if (srcLabel !== '') {
        //srcLabel = '<span style="color:' + srcColour + '">' + srcLabel + '</span>';
        return html`
          <span style=${styleMap({
            color: srcColour,
            display: "inline-block",
            paddingBottom: "9px"
          })}>${srcLabel}</span>
        `;
    }
    return srcLabel;


    function getRouteLabel(path) {
        if (path.params && path.params.label) {
            return path.params.label;
        }
        return getRouteSourceFromPath(path) || '';
    }

    function getRouteSourceFromPath(path) {
        if (!path || !path.src) return 'PWG';
        const src = path.src;
        if (src === 'GFS' && path.weatherSource === 'predictwind') {
            return 'PWG';
        }
        if (src === 'PWC') return 'PWE';
        if (src === 'CMC') return 'ECMWF';
        if (isOffshoreApp && src === 'SPIREBETA') return 'SPIRE';
        return src;
    }

    function getRouteColour(path) {
        if (path.params && path.params.colour) {
            return path.params.colour;
        }
        const routeSource = getRouteSourceFromPath(path);
        return ROUTE_COLOURS[routeSource] || '#000000';
    }
}

class GenericRoutingTables extends LitElement {
    static get properties() {
        return {
            utcOffset: {type: Number},
            routeList: {type: Array},
            stats: {type: Array}
        };
    }

    static get maxDepartureCount() {
        return 4;
    }

    constructor() {
        super();
        this.utcOffset = 0;
        this.routeList = [];
        this.stats = [];
    }

    connectedCallback() {
        super.connectedCallback();
        gmdssDataPromise && gmdssDataPromise.then(gmdssData => {
            this.appendGmdssWarnings(gmdssData);
        });
    }

    // Normally it's not necessary to implement update(); this is just for gathering more debug data on exceptions.
    update(...args) {
        try {
            return super.update(...args);
        } catch (e) {
            window.Sentry?.captureException(e);
            throw e;
        }
    }

    async appendGmdssWarnings(gmdssData) {
        console.time(`${this.localName} gmdssWarnings`);
        for (let i = 0; i < this.routeList.length; i++) {
            let route = this.routeList[i].route,
                routeWarnings = [];
            for (let j = 0; j < route.length; j++) {
                let node = route[j],
                    nodeWarnings = [],
                    nodeWarningText = [];
                if (node.p) {
                    nodeWarnings = await locationGmdssWarnings(node.p, gmdssData);
                    for (let warningType in nodeWarnings) {
                        let [minTimestamp, maxTimestamp] = nodeWarnings[warningType];
                        if (minTimestamp <= node.t && node.t <= maxTimestamp) {
                            nodeWarningText.push(warningType);
                            if (!routeWarnings.includes(warningType)) {
                                routeWarnings.push(warningType);
                            }
                        }
                    }
                }
                node.gmdssWarnings = nodeWarningText;
            }
            route.allGmdssWarnings = routeWarnings;
        }
        this.requestUpdate();
        console.timeEnd(`${this.localName} gmdssWarnings`);
    }

    selectRouteNode(ev) {
        const $row = $j(ev.target).closest('tr'), {routeIndex, nodeIndex} = $row.data();
        console.log('Attempting to select', {routeIndex, nodeIndex});
        const node = this.routeList[routeIndex]?.complete[nodeIndex];
        if (node) {
            let e = new CustomEvent('routing-select-route-node', {
                detail: node, bubbles: true, composed: true
            });
            this.dispatchEvent(e);
        }
    }

    nextDeparture(event) {
        /* Should only ever happen for departure tables on small screens */
        let selected = +this.dataset.selectedDepartureIndex || 0;
        this.dataset.selectedDepartureIndex = (selected + 1) % this.departureCount;
    }

    prevDeparture(event) {
        /* Should only ever happen for departure tables on small screens */
        let selected = +this.dataset.selectedDepartureIndex || 0;
        this.dataset.selectedDepartureIndex = (selected - 1 + this.departureCount) % this.departureCount;
    }

    handleTableScroll(event) {
        // Ticket #16462 – make routing table header sticky.
        let element = event.target;
        let left = element.scrollLeft;
        let table = element.parentElement.querySelector('.table-sticky-header');
        table.scroll({left});
    }

    renderScrollWrappedTable(content) {
        return html`
          <div class="table-scroller-container">
            ${content}
          </div>
        `;
    }

    renderSummaryTable(verbose = false) {
        const
            stats = this.isAggregate ? this.aggregateStats : this.stats,
            sources = new Set(stats.map(s => s.src)),
            sourceCount = sources.size,
            departureCount = getDepartureCount(stats[0].path),
            isDeparturePlan = departureCount > 1,
            departures = Array.from({length: departureCount}, (_, i) => i),  // range(departureCount)
            departureSpan = this.isAggregate ? 1 : sourceCount,
            departureTimes = [],
            details = [],
            routerParams = this.routeList[0]?.params;

        if (isDeparturePlan) {
            console.assert(departureCount <= this.constructor.maxDepartureCount,
                "Too many departures (not implemented!)");
            // Used when switching between departures on small screens
            this.departureCount = departureCount;
        }

        const colgroups = html`
          <colgroup>
            <col>
            ${departures.map(_ => html`
              <col span="${sourceCount}">
            `)}
          </colgroup>
        `;

        if (isDeparturePlan) {
            stats.forEach(function (o) {
                if (o.path.params) {
                    departureTimes[o.path.params.departureIndex] = 'startTime' in o.path.params ?
                        o.path.params.startTime : o.path.route[0].t;
                }
            });
        }

        const rowForDetail = detail => {
            let text = detail.label;
            if (!text && !isDeparturePlan) {
                console.log("Returning nothing for empty detail object.");
                return nothing;
            }
            if (detail.note) {
                text = [text, html`
                  <div class=statistics-label-note>${detail.note}</div>
                `];
            }
            const cells = stats.map((stats, n) => {
                let formatted = '';
                if (text) {
                    formatted = detail.format(stats[detail.field]);
                }
                if (formatted === '') {
                    formatted = '&nbsp;';
                }
                if (!formatted) {
                    formatted = '0';
                }
                if (typeof formatted !== 'string') {
                    throw new Error(
                        `Value ${stats[detail.field]} for field ${detail.field} was not formatted to a string: ${formatted}`
                    );
                }
                formatted = unsafeHTML(formatted);
                //}
                // FIXME departureIndex doesn't make sense for normal routes!
                const departureIndex = Math.floor(n / departureSpan);
                return html`
                  <td data-table-departure-index="${departureIndex}">${formatted}</td>
                `;
            });
            return html`
              <tr class=${classMap({"empty-row": !text})}>
                <td>${text}</td>
                ${cells}
              </tr>
            `;
        };
        const average_results_span = isDeparturePlan && this.isAggregate ?
            html`<span class=average-results>${text_average_results}`
            : nothing;
        const thead_rows = isDeparturePlan ? html`
          <tr class="wide-screen-only table-departure-title">
            <td></td>
            ${departures.map(n => html`
              <th colspan="${sourceCount}" data-table-departure-index="${n}">${interpolate(interpolation_text_departure_n, [n + 1])}`)}
          <tr class="wide-screen-only table-departure-time">
            <td>${average_results_span}</td>
            ${departures.map(n => html`
              <th colspan="${sourceCount}" data-table-departure-index="${n}">
                ${formatTimestamp(departureTimes[n] + PWGMap.utcOffset * 3600)}`)}
          <tr>
            <th></th>
            ${this.isAggregate ? nothing : this.stats.map(obj => html`
              <th data-table-departure-index="${obj.path.params.departureIndex}">${getSourceLongLabel(obj.path)}
            `)}
        ` : html`
          <tr>
            <th></th>
            ${this.routeList.map(obj => html`
              <th>${getSourceLongLabel(obj)}
            `)}
        `;
        const motoring_time_cells = this.stats.map(stat => html`
          <td>${formatTimeDifference(stat.motoringTime * 60 * 60) || '0h'}`
        );
        if (verbose) {
            details.push({label: text_passage_time_days, field: 'totalTime', format: formatHoursToDays2dp});
            if (this.stats.some(s => s.motoringTime !== null)) {
                details.push({label: text_motoring_time_days, field: 'motoringTime', format: formatHoursToDays2dp});
            }
            if (shouldShowFuelConsumption() && this.stats.some(s => s.fuelConsumed !== null) && isDeparturePlan) {
                details.push({
                    label: interpolateUnitLabel(text_fuel_consumption_unit, preference_units.fuel_volume),
                    field: 'fuelConsumed',
                    format: (value) => convertAndFormat(value, units.litres, preference_units.fuel_volume, formats.string_0dp)
                });
            }
            details.push(
                {
                    label: interpolateUnitLabel(text_max_wind_speed, preference_units.wind_speed),
                    field: 'maxWindSpeed',
                    format: (value) => convertAndFormat(value, units.knots, preference_units.wind_speed, formats.string_1dp)
                },
                {
                    label: interpolateUnitLabel(text_min_wind_speed, preference_units.wind_speed),
                    field: 'minWindSpeed',
                    format: (value) => convertAndFormat(value, units.knots, preference_units.wind_speed, formats.string_1dp)
                },
                {
                    label: interpolateUnitLabel(text_avg_wind_speed, preference_units.wind_speed),
                    field: 'aveWindSpeed',
                    format: (value) => convertAndFormat(value, units.knots, preference_units.wind_speed, formats.string_1dp)
                },
                {
                    label: interpolateUnitLabel(text_max_gust, preference_units.wind_speed),
                    field: 'maxGust',
                    format: (value) => convertAndFormat(value, units.knots, preference_units.wind_speed, formats.string_1dp)
                },
                {
                    label: interpolateUnitLabel(text_max_cape, preference_units.cape),
                    field: 'maxCAPE',
                    format: (value) => convertAndFormat(value, units.joules_per_kilogram, preference_units.cape, formats.string_2dp)
                },
                {
                    label: interpolateUnitLabel(text_max_rain, preference_units.rainfall),
                    field: 'maxRain',
                    format: (value) => convertAndFormat(value, units.millimetres_per_hour, preference_units.rainfall, formats.string_1dp)
                },
                {},
                {
                    label: text_time_upwind, field: 'percentUpwind', format: formatPercentage
                },
                {
                    label: (routerParams.boatPolarType == "power") ?
                        text_time_reaching_power : text_time_reaching, field: 'percentReaching', format: formatPercentage
                },
                {
                    label: text_time_downwind, field: 'percentDownwind', format: formatPercentage
                },
                {},
                {
                    label: text_time_lt_8_knots, field: 'percent0to8knots', format: formatPercentage
                },
                {
                    label: text_time_8_to_20_knots, field: 'percent8to20knots', format: formatPercentage
                },
                {
                    label: text_time_20_to_30_knots, field: 'percent20to30knots', format: formatPercentage
                },
                {
                    label: text_time_30_to_40_knots, field: 'percent30to40knots', format: formatPercentage
                },
                {
                    label: text_time_gt_40_knots, field: 'percentOver40knots', format: formatPercentage
                },
                {},
                {
                    label: text_time_upwind_gt_15_knots, field: 'percentUpwindOver15Knots', format: formatPercentage
                },
                {
                    label: text_time_wind_against_current,
                    field: 'percentWindAgainstCurrent',
                    format: formatPercentage,
                    note: text_time_wind_against_current_note
                },
                {},
                {
                    label: text_time_lt_1m_wave, field: 'percent0to1m', format: formatPercentage
                },
                {
                    label: text_time_1m_to_2m_wave, field: 'percent1to2m', format: formatPercentage
                },
                {
                    label: text_time_2m_to_3m_wave, field: 'percent2to3m', format: formatPercentage
                },
                {
                    label: text_time_3m_to_4m_wave, field: 'percent3to4m', format: formatPercentage
                },
                {
                    label: text_time_4m_to_5m_wave, field: 'percent4to5m', format: formatPercentage
                },
                {
                    label: text_time_gt_5m_wave, field: 'percentOver5m', format: formatPercentage
                },
                {
                    label: text_time_missing_wave, field: 'percentMissingWave', format: formatPercentage
                }
            );
            if (!document.body.classList.contains('DestinationForecast')) details.push(
                {},
                {
                    label: text_time_lt_4deg_roll,
                    field: 'percentRollUnder4deg',
                    format: formatPercentage,
                    note: text_time_lt_4deg_roll_note
                },
                {
                    label: text_time_roll_over_4deg,
                    field: 'percentRollOver4deg',
                    format: formatPercentage,
                    note: text_time_roll_over_4deg_note
                },
                {},
                {
                    label: text_time_low_vertical_acc,
                    field: 'percentVerticalAccUnder0_2g',
                    format: formatPercentage,
                    note: text_time_low_vertical_acc_note
                },
                {
                    label: text_time_high_vertical_acc,
                    field: 'percentVerticalAccOver0_2g',
                    format: formatPercentage,
                    note: text_time_high_vertical_acc_note
                },
                {},
                {
                    label: text_time_low_slamming_inc,
                    field: 'percentSlammingIncUnder50percent',
                    format: formatPercentage
                },
                {
                    label: text_time_high_slamming_inc,
                    field: 'percentSlammingIncOver50percent',
                    format: formatPercentage,
                    note: text_time_high_slamming_inc_note
                },
                {},
                {
                    label: text_time_lt_100_polar_due_to_waves,
                    field: 'percentPolarUnder100percent',
                    format: formatPercentage
                },
                {
                    label: text_time_gt_100_polar_due_to_waves,
                    field: 'percentPolarOver100percent',
                    format: formatPercentage
                }
            );
        }

        if (!isDeparturePlan) {
            const summaries = this.routeList.map(obj => {
                let route = obj.route || [];
                if (verbose) addWarningsToRoute(route, ROUTING_INVALID_VALUE);
                return routeSummary(route, obj.params);
            });
            const warning_cells = this.routeList.map(obj => obj.route.WARNINGS.size || obj.route.allGmdssWarnings?.length ? html`
                      <td>
                        <pw-routing-table-warning class=summary
                                                  .warnings=${Array.from(obj.route.WARNINGS)}
                                                  .gmdssWarnings=${obj.route.allGmdssWarnings}></pw-routing-table-warning>
                      </td>
                ` : html`
                      <td></td>`
            );
            const details_rows = details.filter(o => !$j.isEmptyObject(o)).map(rowForDetail);
            const debug_rows = window.showDebug ? html`
              <tr>
                <td>${text_processing_time}</td>
                ${(this.routeList.map(obj => html`
                  <td>${format1dp(obj.processingTime)}</td>
                `))}
            ` : nothing;
            const time_taken_cells = summaries.map(summary => html`
              <td>${summary.timeTaken ?? dash}
            `);
            return this.renderScrollWrappedTable(html`
              <div class="table-sticky-header summary-header">
                <table>
                  <thead>
                  ${thead_rows}
                </table>
              </div>
              <div class="table-scroller" @scroll="${this.handleTableScroll}">
                <table class=summary>
                  ${colgroups}

                  <tbody>
                  <tr>
                    <td>${text_warnings}</td>
                    ${warning_cells}
                  </tr>
                  ${debug_rows}
                  <tr>
                    <td>${text_timezone}</td>
                    ${summaries.map(summary => html`
                      <td>${summary.timeZone ?? dash}`)}
                  <tr>
                    <td>${text_start_time}</td>
                    ${summaries.map(summary => html`
                      <td>${summary.startTime ?? dash}`)}
                  <tr>
                    <td>${text_end_time}</td>
                    ${summaries.map(summary => html`
                      <td>${summary.endTime ?? dash}`)}
                  <tr>
                    <td>${text_time_taken}</td>
                    ${time_taken_cells}
                  </tr>
                  ${!shouldShowFuelConsumption()
                          ? html`
                            <tr>
                              <td>${text_motoring_time}</td>
                              ${motoring_time_cells}
                            </tr>`
                          : nothing}
                  ${shouldShowFuelConsumption() && summaries.some(s => s.fuelConsumed !== null)
                          ? html`
                            <tr>
                              <td>${interpolateUnitLabel(text_fuel_consumption_unit, preference_units.fuel_volume)}</td>
                              ${summaries.map(summary => html`
                                <td>${summary.fuelConsumed ?? dash}`)}
                            </tr>`
                          : nothing}
                  <tr>
                    <td>${text_distance_travelled}</td>
                    ${summaries.map(summary => html`
                      <td>${summary.distanceTravelled ?? dash}`)}
                  <tr>
                    <td>${interpolateUnitSymbol(text_average_speed, preference_units.boat_speed)}</td>
                    ${summaries.map(summary => html`
                      <td>${summary.averageSpeed ?? dash}`)}
                  </tr>
                  ${details_rows}
                  </tbody>
                </table>
              </div>
            `);
        } else {
            // oddly there's no 'arrow function' that's also a 'generator function'
            const that = this;

            function* tableBodies() {
                // Turn the details array into a nested HTML structure. We use multiple tbody elements so that
                // our border-radius styles on tr:first-child and tr:last-child apply for each section within
                // a single table.
                let rows = [];
                for (const detail of details) {
                    if ($j.isEmptyObject(detail)) {
                        // We have reached a separator row; each separator and each separated section is a new tbody.
                        // First yield a tbody with accumulated normal rows
                        yield html`
                          <tbody>${rows}</tbody>`;
                        // Now forget about those rows
                        rows = [];
                        // Now yield a tbody with an "empty" row
                        yield html`
                          <tbody class="empty-tbody">
                          ${rowForDetail({})}
                          </tbody>
                        `;
                    } else {
                        // Normal row, accumulate it.
                        rows.push(rowForDetail(detail));
                    }
                }
                // Finally yield remaining rows
                yield html`
                  <tbody>${rows}</tbody>
                `;
            }

            const table = html`
              <table class=summary>
                ${colgroups}
                <thead>
                ${thead_rows}
                </thead>
                ${tableBodies()}
              </table>
            `;
            return html`
              <div class="narrow-screen-only departure-selector">
                <span class="back-arrow" @click=${this.prevDeparture}></span>
                ${departures.map(n => html`
                  <span data-table-departure-index="${n}">
                      <span class="departure-title">${interpolate(interpolation_text_departure_n, [n + 1])}</span>
                      <span class="departure-time">${formatTimestamp(departureTimes[n] + PWGMap.utcOffset * 3600)}</span>
                    </span>
                `)}
                <span class="forward-arrow" @click=${this.nextDeparture}></span>
              </div>
              <div class=narrow-screen-only>
                ${average_results_span}
              </div>
              ${table}
            `;
        }
    }

    render() {
        return html`
          <div class=routes>
            ${this.routeList.map(this.renderRoute, this)}
          </div>
        `;
    }

    static get styles() {
        // language=css
        return css`
            :host {
                font-size: 12px;
                display: flex;
                flex-flow: column nowrap;
                gap: 27px;
                align-items: center;
            }

            div.summary {
                display: flex;
                align-items: center;
            }

            .summary td:first-child,
            .summary th:first-child {
                white-space: normal;
            }

            .departure-selector {
                position: sticky;
                top: -1px;
                background: white;
                display: flex;
                align-items: center;
                width: auto;
            }

            .back-arrow,
            .forward-arrow {
                flex-grow: 1;
                width: 9px;
                height: 56px;
                position: relative;
            }

            .back-arrow {
                text-align: left;
            }

            .forward-arrow {
                text-align: right;
            }

            /* Draw arrowhead icons by drawing solid borders and transforming them */
            .back-arrow::before,
            .back-arrow::after,
            .forward-arrow::before,
            .forward-arrow::after {
                content: "";
                display: inline-block;
                border: thin solid;
                color: #2996FE;
                height: 10px;
                position: absolute;
            }

            .back-arrow::before,
            .back-arrow::after {
                left: 20px;
            }

            .forward-arrow::before,
            .forward-arrow::after {
                right: 20px;
            }

            .back-arrow::before,
            .forward-arrow::before {
                bottom: 27px;
            }

            .back-arrow::after,
            .forward-arrow::after {
                top: 27px;
            }

            .back-arrow::before,
            .forward-arrow::after {
                transform: rotate(30deg);
            }

            .back-arrow::after,
            .forward-arrow::before {
                transform: rotate(-30deg);
            }

            /*noinspection CssOverwrittenProperties*/
            .routes {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-start;
                justify-content: center;
                /*noinspection CssInvalidPropertyValue*/
                justify-content: center safe;
                row-gap: 27px;
            }

            .source-header {
                font-weight: bold;
                font-size: 18px;
            }

            .statistics-label-note {
                color: ${grey(0.4)};
            }

            table {
                border-spacing: 0;
            }

            .routes tbody tr {
                cursor: pointer;
            }

            th, td {
                white-space: nowrap;
            }

            th {
                text-align: left;
            }

            td:not(:first-child),
            th:not(:first-child) {
                padding: 0 10px;
            }

            th:first-child {
                padding: 0 9px;
            }

            .table-sticky-header, thead {
                position: sticky;
                top: -20px;
                z-index: 10;
                background-color: white;
            }


            th:first-child, .table-sticky-header table {
                background: white;
            }

            td:first-child {
                padding: 9px;
                color: ${grey(0.7)};
            }

            td:not(:first-child) {
                font-weight: 600;
                font-size: 14px;
                color: ${grey(0.8)};
            }

            /* Checkerboard pattern */
            col:nth-child(even) {
                background: rgba(26, 47, 67, 0.04);
                background: ${grey(0.04)}; /* opaque */
            }

            tbody tr:nth-child(odd) {
                background: rgba(26, 47, 67, 0.03);
                background: ${grey(1, 0.03)}; /* partially transparent */
            }

            tbody tr:nth-child(even) {
                background: rgba(26, 47, 67, 0.07);
                background: ${grey(1, 0.07)}; /* partially transparent */
            }

            /* end checkerboard */

            /* override checkerboard for separator rows, and headers */
            table.summary .empty-row th,
            table.summary .empty-row td,
            thead th, thead td {
                background-color: white;
                height: 0;
            }

            tbody > tr:first-child td:first-child {
                border-top-left-radius: 8px;
            }

            tbody > tr:last-child td:first-child {
                border-bottom-left-radius: 8px;
            }

            tbody > tr:first-child td:last-child {
                border-top-right-radius: 8px;
            }

            tbody > tr:last-child td:last-child {
                border-bottom-right-radius: 8px;
            }

            .table-departure-time th,
            .table-departure-title th,
            .departure-title,
            .departure-time {
                text-align: center;
                font-size: 14px;
            }

            .table-departure-time th,
            .departure-time {
                font-weight: 600;
            }

            .departure-time {
                color: ${grey(1)};
                padding-left: 7px;
            }

            .table-departure-time th,
            .table-departure-time td {
                padding-bottom: 19px;
                padding-top: 0;
                vertical-align: bottom;
            }

            .table-departure-title th,
            .departure-title {
                font-weight: 700;
                padding-bottom: 8px;
            }

            .table-scroller-container {
                width: 100%;
            }

            .table-scroller table, .table-sticky-header table {
                flex: 1 0 100%;
                table-layout: fixed;
            }

            /* Phones and narrow tablets in portrait */
            @media only screen
            and (max-width: 736px) /* Should we change MAX_PHONE_LAYOUT_WIDTH to be 736px? GHB */
            and (orientation: portrait) {
                .wide-screen-only {
                    display: none;
                }

                ul {
                    margin: 0;
                    padding-left: 20px; /* Smaller than user-agent value */
                }

                td:not(:first-child),
                th:not(:first-child) {
                    padding-right: 0;
                }

                .table-sticky-header {
                    pointer-events: none;
                }

                .table-scroller tr, .table-sticky-header tr {
                    display: flex;
                }

                .table-scroller-container {
                    position: relative;
                    overflow: visible;
                    max-width: 100vw;
                }

                .table-scroller-container::after {
                    width: 80px;
                    display: inline-block;
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    pointer-events: none;
                    background: linear-gradient(to right, rgba(255 255 255 / 0), white);
                    z-index: 12;
                }

                .table-sticky-header table {
                    background: white;
                }

                .table-scroller, .table-sticky-header {
                    overflow-x: auto;
                    overflow-y: visible;
                    display: flex;
                    max-width: 100%;
                }

                .table-scroller::after, .table-sticky-header::after {
                    content: "";
                    flex: 1 0 80px;
                }

                .summary-header th {
                    width: 75px;
                }

                .summary-header th:first-child {
                    position: sticky;
                    left: 0;
                }

                .table-scroller table tr {
                    display: flex;
                }

                .table-sticky-header td:not(:first-child), .table-scroller td:not(:first-child) {
                    padding: 10px 0 10px 10px;
                }

                .table-scroller td:first-child, .table-sticky-header td:first-child {
                    position: -webkit-sticky;
                    position: sticky;
                    left: 0px;
                    z-index: 1;

                }

                .table-sticky-header td:first-child {
                    background: linear-gradient(to left, rgba(255 255 255 / 0), white 9px);
                }

                .table-scroller tr:nth-child(even) td:first-child {
                    background: linear-gradient(to left, ${grey(0.07, 0)}, ${grey(0.07, 1)} 9px);
                }

                .table-scroller tr:nth-child(odd) td:first-child {
                    background: linear-gradient(to left, ${grey(0.03, 0)}, ${grey(0.03, 1)} 9px);
                }

                .average-results {
                    display: block;
                    text-align: center;
                }

                td[data-table-departure-index] {
                    padding-right: 10px;
                }

                /* I can't figure out how to nicely generate this with code... css tag will not accept an interable of
                 css objects apparently, just one css object :(
                 I could put each item into the top-level array returned by the styles() function but
                 that means I would need to emit the media query every time which seems inefficient!
                  */
                :host([data-selected-departure-index="0"]) [data-table-departure-index]:not([data-table-departure-index="0"]),
                :host([data-selected-departure-index="1"]) [data-table-departure-index]:not([data-table-departure-index="1"]),
                :host([data-selected-departure-index="2"]) [data-table-departure-index]:not([data-table-departure-index="2"]),
                :host([data-selected-departure-index="3"]) [data-table-departure-index]:not([data-table-departure-index="3"]) {
                    display: none;
                }
            }

            /* Inverse media query — all other cases */
            @media not screen
            and (max-width: 736px)
            and (orientation: portrait) {
                .narrow-screen-only {
                    display: none;
                }

                .routes > * {
                    flex-grow: 1;
                    min-width: 378px;
                }

                .routes th:last-child,
                .routes td:last-child {
                    background: white;
                    padding: 0 20px 0 5px;
                    min-height: 36px;
                }

                .routes tbody > tr:first-child td:nth-last-child(2) {
                    border-top-right-radius: 8px;
                }

                .routes tbody > tr:last-child td:nth-last-child(2) {
                    border-bottom-right-radius: 8px;
                }

                pw-routing-table-warning {
                    position: relative;
                    top: 1px;
                }

                .table-scroller, .table-sticky-header {
                    display: flex;
                }

                .table-scroller > *, .table-sticky-header > * {
                    flex-grow: 1;
                }
            }
        `;
    }
}

export class PWRoutingRouteTables extends GenericRoutingTables {
    renderRoute(routeListItem, routeIndex) {
        const route = routeListItem.complete || [];
        addWarningsToRoute(route, ROUTING_INVALID_VALUE); // ??? Do that here?
        const labels = {
            time_short: text_time,
            lat: text_latitude,
            lon: text_longitude,
            depth: text_depth,
            accumfuelconsumed: interpolateUnitSymbol(text_fuel_consumption_unit_short, preference_units.fuel_volume),
            warnings: ''
        };
        const fields = shouldShowFuelConsumption() && route.some(node => (node.accumfuelconsumed ?? null) !== null)
            ? ['time_short', 'lat', 'lon', 'depth', 'accumfuelconsumed', 'warnings']
            : ['time_short', 'lat', 'lon', 'depth', 'warnings'];
        return html`
          <table>
            <colgroup>
              <col>
              <col span=${fields.length - 1}>
            </colgroup>
            <thead>
            <tr>
              <th colspan=${fields.length} class=source-header>
                ${getSourceLongLabel(routeListItem)}
            <tr>${fields.map(field => html`
              <th>${labels[field]}`)}
            <tbody>
            ${route.map((node, nodeIndex) => {
              const {formatted} = getUserNode(node);
              return html`
                <tr>
                  ${fields.map(field =>
                          field !== 'warnings'
                                  ? html`
                                    <td @click=${this.selectRouteNode}
                                        data-route-index=${routeIndex}
                                        data-node-index=${nodeIndex}>${formatted[field]}
                                    </td>`
                                  : html`
                                    <td>${node.WARNINGS?.size || node.gmdssWarnings
                                            ? html`
                                              <pw-routing-table-warning .warnings=${Array.from(node.WARNINGS || new Set())}
                                                                        .gmdssWarnings=${node.gmdssWarnings}></pw-routing-table-warning>`
                                            : nothing}
                                    </td>`
                  )}
                </tr>`;
            })}
          </table>
        `;
    }

    static get styles() {
        return [super.styles, // language=css
            css`
                td:not(:last-child) {
                    cursor: pointer;
                }
            `];
    }
}

export class PWRoutingSummaryTables extends GenericRoutingTables {

    render() {
        return this.renderSummaryTable(true);
    }

    static get styles() {
        return [super.styles, // language=css
            css`

                .summary-header th {
                    height: auto;
                }

                .summary td:first-child {
                    width: 168px;
                }

                .summary-header th:first-child {
                    width: 180px;
                }

                .summary td:not(:first-child), .summary-header th:not(:first-child) {
                    width: 120px;
                }

                @media only screen
                and (max-width: 736px) /* Should we change MAX_PHONE_LAYOUT_WIDTH to be 736px? GHB */
                and (orientation: portrait) {

                    .summary-header th:not(:first-child), .summary td:not(:first-child) {
                        width: 120px;
                    }

                    .summary td:first-child, .summary-header th:first-child {
                        width: 120px;
                    }
                }
            `];
    }
}

export class PWRoutingWindTables extends GenericRoutingTables {
    renderRoute(routeListItem, routeIndex) {
        const route = routeListItem.complete || [];
        addWarningsToRoute(route, ROUTING_INVALID_VALUE);
        // TODO: find a better way of doing this (overriding beaufort with knots and changing the label here)
        const bsp_overridden_unit_suffix = GET_FIELD_UNIT(routing_units.node_user.fields.sog).overridden ? html` (${getUnitSymbol(GET_FIELD_UNIT(routing_units.node_user.fields.sog))})` : html``;
        const
            isDestinationForecast = document.body.classList.contains('DestinationForecast'),
            numColumns = isDestinationForecast ? 5 : 8,
            content = html`
              <div class="table-sticky-header">
                <table>
                  <thead>
                  <tr>
                    <th class=source-header>${getSourceLongLabel(routeListItem)}
                    <th></th>
                  <tr>
                    <th class=time>${text_time}</th>
                    <th class=tws>${text_tws}</th>
                    <th class=gust>${text_gust}</th>
                    <th class=twd>${text_twd}</th>
                    ${isDestinationForecast ? nothing : html`
                      <th class=twa>${text_twa}</th>
                      <th class=cog>${text_heading}</th>
                      <th class=sog>${text_bsp} ${bsp_overridden_unit_suffix}</th>
                    `}
                    <th></th>
                </table>
              </div>
              <div class="table-scroller" @scroll="${this.handleTableScroll}">
                <table>
                  <colgroup>
                    <col>
                    <col span=${numColumns - 1}>
                  </colgroup>
                  <tbody>
                  <tr class="rect"></tr>
                  ${route.map((node, nodeIndex) => {
                    if (node.tws == null) return nothing;
                    const formattedNode = getFormattedTableNode(node);
                    const time = formattedNode.time_short ?? dash;
                    let tws = formattedNode.tws ?? dash;
                    let gust = formattedNode.gust ?? dash;
                    let twd = formattedNode.twd ?? dash;
                    let twa = formattedNode.twa ? unsafeHTML(formattedNode.twa) : dash;
                    let heading = formattedNode.heading ?? dash;
                    let bsp = formattedNode.bsp ?? dash;
                    let motoring = isset(node.motoring, ROUTING_INVALID_VALUE) && node.motoring ? 'M' : '';
                    if (bsp != '0' && motoring == 'M') {
                      bsp = [bsp, html`&nbsp;&#x1F6A2;`]; // Ship emoji
                    }
                    if (tws == '0' && twd == '0' && twa == '0') {
                      tws = '';
                      gust = '';
                      twd = '';
                      twa = '';
                      bsp = '';
                      heading = '';
                    }
                    return html`
                      <tr @click=${this.selectRouteNode}
                          data-route-index=${routeIndex}
                          data-node-index=${nodeIndex}>
                        <td class=time>${time}
                        <td>${tws}
                        <td>${gust}
                        <td>${twd}</td>
                        ${document.body.classList.contains('DestinationForecast') ? nothing : html`
                          <td>${twa}
                          <td>${heading}
                          <td>${bsp}
                        `}
                        <td>${node.WARNINGS?.size || node.gmdssWarnings ? html`
                          <pw-routing-table-warning .warnings=${Array.from(node.WARNINGS || new Set())}
                                                    .gmdssWarnings=${node.gmdssWarnings}>
                          </pw-routing-table-warning>
                        ` : nothing}
                    `;
                  })}
                </table>
              </div>
            `;
        // For a DestinationForecast the table is so narrow there's no point wrapping it in a scroll container.
        return isDestinationForecast ? content : this.renderScrollWrappedTable(content);
    }

    render() {
        // noinspection JSValidateTypes
        return [this.renderSummaryTable(), super.render()];
    }

    static get styles() {
        // noinspection JSValidateTypes
        return [super.styles, // language=css
            css`
                .routes td:first-child, .routes th:first-child {
                    width: 150px;
                }

                .routes td:not(:first-child), .routes th:not(:first-child) {
                    width: 110px;
                }

                .routes td:last-child, .routes th:last-child {
                    width: 40px;
                }

                .summary td:not(:first-child), .summary-header th:not(:first-child) {
                    width: 120px;
                }


                .summary td:first-child, .summary-header th:first-child {
                    width: 220px;
                }

                td, th {
                    padding-right: 0;
                }

                /* Phones and narrow tablets in portrait */
                @media only screen
                and (max-width: 736px)
                and (orientation: portrait) {
                    .summary-header tr {
                        display: flex;
                    }

                    table.summary td:not(:first-child), .summary-header th:not(:first-child) {
                        width: 125px;
                    }

                    .summary td:first-child, .summary-header th:first-child {
                        width: 100px;
                    }

                    .table-sticky-header thead th {
                        height: auto;
                    }

                    .routes .table-scroller td:nth-of-type(1), .routes .table-sticky-header th:nth-of-type(1) {
                        position: sticky;
                        left: 0;
                        width: 81px;
                    }

                    .routes .table-scroller table td:nth-of-type(2), .routes .table-sticky-header table th:nth-of-type(2),
                    .routes .table-scroller table td:nth-of-type(3), .routes .table-sticky-header table th:nth-of-type(3),
                    .routes .table-scroller table td:nth-of-type(4), .routes .table-sticky-header table th:nth-of-type(4),
                    .routes .table-scroller table td:nth-of-type(5), .routes .table-sticky-header table th:nth-of-type(5),
                    .routes .table-scroller table td:nth-of-type(6), .routes .table-sticky-header table th:nth-of-type(6) {
                        width: 40px;
                    }

                    .routes .table-scroller table td:nth-of-type(7), .routes .table-sticky-header table th:nth-of-type(7) {
                        width: 60px;
                    }

                    .routes .table-scroller table td:nth-of-type(7), .routes .table-sticky-header table th:nth-of-type(7) {
                        width: 60px;
                    }

                }
            `];
    }
}

export class PWRoutingCurrentTables extends GenericRoutingTables {
    renderRoute(routeListItem, routeIndex) {
        const route = routeListItem.complete || [];
        addWarningsToRoute(route, ROUTING_INVALID_VALUE); // ??? Do that here?
        const numColumns = 4;
        return html`
          <table>
            <colgroup>
              <col>
              <col span=${numColumns - 1}>
            </colgroup>
            <thead>
            <tr>
              <th colspan=${numColumns} class=source-header>
                ${getSourceLongLabel(routeListItem)}
            <tr>${[text_time, text_speed, text_direction, ''].map(s => html`
              <th>${s}`)}
            <tbody>
            ${route.map((node, nodeIndex) => {
              const {formatted} = getUserNode(node);
              return html`
                <tr>
                  ${[formatted.time_short, formatted.cs, formatted.cd].map(s => html`
                    <td @click=${this.selectRouteNode}
                        data-route-index=${routeIndex}
                        data-node-index=${nodeIndex}>${s ?? dash}
                  `)}
                  <td>
                    ${node.WARNINGS?.size || node.gmdssWarnings ? html`
                      <pw-routing-table-warning .warnings=${Array.from(node.WARNINGS || new Set())}
                                                .gmdssWarnings=${node.gmdssWarnings}>
                      </pw-routing-table-warning>
                    ` : nothing}
              `;
            })}
          </table>
        `;
    }

    render() {
        // noinspection JSValidateTypes
        return [html`
          <ul class=current-disclaimers>
            <li>${text_optional_ocean_currents}
            <li>${text_effects_of_currents}
            <li>${text_no_data_dash}
            <li>${text_convention_for_current_direction}
          </ul>
        `, super.render()];
    }

    static get styles() {
        return [super.styles, // language=css
            css`
                .current-disclaimers {
                    color: ${grey(0.7)};
                    align-self: self-start;
                }

                .current-disclaimers li {
                    max-width: 800px;
                    margin: 0 0 16px 0;
                }
            `];
    }
}


export class PWRoutingAtmosphereTables extends GenericRoutingTables {
    renderRoute(routeListItem, routeIndex) {
        const route = routeListItem.complete || [];
        addWarningsToRoute(route, ROUTING_INVALID_VALUE);

        const fields = ['time_short', 'press', 'temp', 'rain', 'cape', 'fog', 'lightning'];
        const numColumns = fields.length + 1; // +1 for warnings

        return this.renderScrollWrappedTable(html`

          <div class="table-sticky-header">
            <table>
              <thead>
              <tr>
                <th class=source-header>${getSourceLongLabel(routeListItem)}
                <th>
              <tr>
                <th>${text_time}
                <th>${text_pressure}
                <th>${text_temperature}
                <th>${text_rain}
                <th>${text_CAPE}
                <th>${text_fog}
                <th>${text_lightning}
                <th>
            </table>
          </div>
          <div class="table-scroller" @scroll="${this.handleTableScroll}">
            <table>
              <colgroup>
                <col>
                <col span=${numColumns - 1}>
              </colgroup>

              <tbody>
              ${route.map((node, nodeIndex) => {
                const {formatted} = getUserNode(node);
                return html`
                  <tr>
                    ${fields.map(field => html`
                      <td @click=${this.selectRouteNode}
                          data-route-index=${routeIndex}
                          data-node-index=${nodeIndex}>${formatted[field] ?? dash}
                    `)}
                    <td>
                      ${node.WARNINGS?.size || node.gmdssWarnings ? html`
                        <pw-routing-table-warning .warnings=${Array.from(node.WARNINGS || new Set())}
                                                  .gmdssWarnings=${node.gmdssWarnings}>
                        </pw-routing-table-warning>
                      ` : nothing}
                  </tr>
                `;
              })}
            </table>
          </div>
        `);
    }

    static get styles() {
        return [super.styles, // language=css
            css`
                .table-sticky-header tr:nth-child(2) {
                    height: 18px;
                }

                td:first-child, th:first-child {
                    width: 140px;
                }

                td:not(:first-child), th:not(:first-child) {
                    width: 75px;
                }

                td:last-child, th:last-child {
                    width: 40px;
                }

                @media only screen
                and (max-width: 736px)
                and (orientation: portrait) {
                    .table-scroller tr, .table-sticky-header tr {
                        display: flex;
                    }

                    .table-scroller table td:nth-of-type(1), .table-sticky-header table th:nth-of-type(1) {
                        width: 85px;
                        height: auto;
                        position: sticky;
                        left: 0;
                    }

                    .table-scroller table td:nth-of-type(2), .table-sticky-header table th:nth-of-type(2) {
                        width: 65px;
                    }

                    .table-scroller table td:nth-of-type(3), .table-sticky-header table th:nth-of-type(3) {
                        width: 85px;
                    }

                    .table-scroller table td:nth-of-type(4), .table-sticky-header table th:nth-of-type(4) {
                        width: 30px;
                    }

                    .table-scroller table td:nth-of-type(5), .table-sticky-header table th:nth-of-type(5) {
                        width: 35px;
                    }

                    .table-scroller table td:nth-of-type(6), .table-sticky-header table th:nth-of-type(6) {
                        width: 30px;
                    }

                    .table-scroller table td:nth-of-type(7), .table-sticky-header table th:nth-of-type(7) {
                        width: 80px;
                    }

                    .table-scroller table td.time {
                        width: 68px;
                    }
                }

                @media only screen
                and (max-device-width: 736px)
                and (orientation: landscape) {

                    .table-scroller-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .table-scroller table td:nth-of-type(1), .table-sticky-header table th:nth-of-type(1) {
                        width: 85px;
                        height: auto;
                        position: sticky;
                        left: 0;
                    }

                    .table-scroller table td:nth-of-type(2), .table-sticky-header table th:nth-of-type(2) {
                        width: 45px;
                    }

                    .table-scroller table td:nth-of-type(3), .table-sticky-header table th:nth-of-type(3) {
                        width: 70px;
                    }

                    .table-scroller table td:nth-of-type(4), .table-sticky-header table th:nth-of-type(4) {
                        width: 30px;
                    }

                    .table-scroller table td:nth-of-type(5), .table-sticky-header table th:nth-of-type(5) {
                        width: 35px;
                    }

                    .table-scroller table td:nth-of-type(6), .table-sticky-header table th:nth-of-type(6) {
                        width: 30px;
                    }

                    .table-scroller table td:nth-of-type(7), .table-sticky-header table th:nth-of-type(7) {
                        width: 80px;
                    }

                    .table-scroller table td.time {
                        width: 68px;
                    }
                }
            `];
    }
}


class GenericRoutingUnits extends LitElement {
    static get properties() {
        return {
            routeList: {type: Array}
        };
    }

    constructor() {
        super();
        this.routeList = [];
    }

    renderPolarSettings() {
        if (document.body.classList.contains('DestinationForecast')) {
            return nothing;
        }

        function appendPolarFieldValue(existing, text, value, dp, sep) {
            if (existing.length) {
                existing.push(sep || ', ');
            }
            existing.push(text);
            existing.push(html`&nbsp;`);
            existing.push(formatRounded(value, dp));
            return existing;
        }

        const routerParams = this.routeList[0]?.params;
        if (!routerParams || !routerParams.boatPolarType) return nothing;

        let polarText = [];
        switch (routerParams.boatPolarType) {
            case "predefined":
                polarText = [routerParams.predefinedPolarName];
                break;
            case "sail":
                polarText = appendPolarFieldValue(polarText, text_upwind_50_deg_colon, routerParams.basicPolarUpwind, 1);
                polarText = appendPolarFieldValue(polarText, html`90&deg;:`, routerParams.basicPolar90Degrees, 1);
                polarText = appendPolarFieldValue(polarText, text_downwind_160_deg_colon, routerParams.basicPolarDownwind, 1);
                break;
            case "advanced":
                polarText = [text_advanced];
                break;
            case "power":
                if (routerParams.useFuelOptimizer) {
                    // TODO: unit conversions
                    polarText = appendPolarFieldValue(polarText, text_significant_height, routerParams.boat.significantHeight, 1);
                    polarText.push('m');
                    polarText = appendPolarFieldValue(polarText, text_eco_bsp, routerParams.boat.economicBoatSpeed, 1);
                    polarText.push(' kts');
                    polarText = appendPolarFieldValue(polarText, text_eco_rpm, routerParams.boat.economicRPM, 0);
                    polarText = appendPolarFieldValue(polarText, text_eco_fuel_rate, routerParams.boat.economicFuelConsumption, 1);
                    polarText.push(' L/hr');
                } else {
                    polarText = appendPolarFieldValue(polarText, text_upwind_colon, routerParams.powerPolarUpwind, 1);
                    polarText = appendPolarFieldValue(polarText, html`90&deg;:`, routerParams.powerPolar90Degrees, 1);
                    polarText = appendPolarFieldValue(polarText, text_downwind_VMG_colon, routerParams.powerPolarDownwind, 1);
                    polarText = appendPolarFieldValue(polarText, text_maximum_speed_no_wind_colon, routerParams.powerPolarNoWind, 1);
                }
                break;
        }

        let motoringText = [];
        if (routerParams.boatPolarType !== 'power') {
            if (routerParams.motoringEnabled) {
                motoringText = interpolate(
                    interpolation_text_will_motor_when_knots,
                    [formatRounded(routerParams.motoringSpeed, 1), formatRounded(routerParams.motoringThreshold, 1)]
                );
            } else {
                motoringText = text_false;
            }
        }

        const polarUpwindAdjustment = routerParams.comfort && routerParams.comfort.performanceupwind || 1;
        const polarDownwindAdjustment = routerParams.comfort && routerParams.comfort.performancedownwind || 1;

        const humanReadableCurrentSourceMap = {
            "mercator": "Mercator",
            "rtofs": "ROTFS",
        }
        const oceanCurrentsRow = routerParams.currentsEnabled ? html`
                  <tr>
                    <th>Ocean Current
                    <td>${routerParams.oceanCurrentsSource in humanReadableCurrentSourceMap ?
                            humanReadableCurrentSourceMap[routerParams.oceanCurrentsSource]
                            : routerParams.oceanCurrentsSource}
                ` : nothing
        // FIXME more gettext() calls, assigned to constants at top-of-file
        return html`
          <div class=polar-settings>
            <h1>Polar Settings</h1>
            <div class=settings-tables>
              <table>
                <tr>
                  <th>Polar
                  <td>${polarText}
                <tr>
                  <th>Polar Speed Adjustment
                  <td>${text_upwind}&nbsp;${formatRounded(polarUpwindAdjustment * 100, 0)}% &mdash;
                    ${text_downwind}&nbsp;${formatRounded(polarDownwindAdjustment * 100, 0)}%
                <tr>
                  <th>Motoring
                  <td>${motoringText}
              </table>
              <table>
                ${oceanCurrentsRow}
                <tr>
                  <th>TWD Adjustment</th>
                  <td>${formatRounded(routerParams.twdAdjustment, 0)}&deg;
                <tr>
                  <th>TWS Scale Factor
                  <td>${formatRounded(routerParams.twsScaling * 100, 0)}%
              </table>
            </div>
          </div>
        `;
    }

    renderComfortSettings() {
        const routerParams = this.routeList[0]?.params;
        if (window.app) {
            if (!app.getSetting("Routing_OptimiseForComfort")) return nothing;
        } else if (!routerParams || routerParams.optimiseFor !== "comfort") {
            return nothing;
        }
        const comfortSettings = routerParams.comfort;
        return html`
          <div class=comfort-settings>
            <div class='settings-tables optimisation-table'>
              <table>
                <tr>
                  <th>${text_optimise_for}
                  <td>${text_comfort}
                </tr>
              </table>
            </div>
            <div class='settings-tables comfort-table'>
              <table>
                <tr class=mobile-optimisation-row>
                  <th>${text_optimise_for}
                  <td>${text_comfort}
                </tr>
                <tr>
                  <th>${text_avoid_wind_speeds}
                  <td><span class=comfort-data><span class=regular-weight>${text_upwind}</span>
                                    ${convertAndFormat(comfortSettings.maxGWSupwind, units.metres_per_second, preference_units.wind_speed, formats.string_0dp)}
                                    ${getUnitLabel(preference_units.wind_speed)}
                                </span>
                    <span class=comfort-data>
                                    <span class=regular-weight>${routerParams.boatPolarType == "power" ? text_on_the_beam : text_reaching}</span>
                                    ${convertAndFormat(comfortSettings.maxGWSreaching, units.metres_per_second, preference_units.wind_speed, formats.string_0dp)}
                                    ${getUnitLabel(preference_units.wind_speed)}
                                </span>
                    <span class=comfort-data><span class=regular-weight>${text_downwind}</span>
                                    ${convertAndFormat(comfortSettings.maxGWSdownwind, units.metres_per_second, preference_units.wind_speed, formats.string_0dp)}
                                    ${getUnitLabel(preference_units.wind_speed)}
                                </span>
                </tr>
                <tr>
                  <th>${text_avoid_wave_heights}
                  <td><span class=comfort-data><span class=regular-weight>${text_upwind}</span>
                                    ${convertAndFormat(comfortSettings.maxwaveheightupwind, units.metres, preference_units.wave_height, formats.string_0dp)}
                                    ${getUnitLabel(preference_units.wave_height)}
                                </span>
                    <span class=comfort-data>
                                    <span class=regular-weight>${routerParams.boatPolarType == "power" ? text_on_the_beam : text_reaching}</span>
                                    ${convertAndFormat(comfortSettings.maxwaveheightreaching, units.metres, preference_units.wave_height, formats.string_0dp)}
                                    ${getUnitLabel(preference_units.wave_height)}
                                </span>
                    <span class=comfort-data><span class=regular-weight>${text_downwind}</span>
                                    ${convertAndFormat(comfortSettings.maxwaveheightdownwind, units.metres, preference_units.wave_height, formats.string_0dp)}
                                    ${getUnitLabel(preference_units.wave_height)}
                                </span>
                </tr>
              </table>
            </div>
          </div>
        `;
    }

    static get styles() {
        // language=css
        return css`
            :host {
                font-size: 12px;
                margin-bottom: 40px;
            }

            :host, .settings-tables, .comfort-settings {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                gap: 6px;
            }

            @supports (justify-content: space-between) {
                :host {
                    justify-content: space-between;
                }
            }

            .polar-settings, .comfort-settings, .optimisation-table {
                flex-grow: 1;
            }

            .comfort-table {
                flex-grow: 3;
            }

            .mobile-optimisation-row {
                display: none;
            }

            .comfort-table td {
                display: flex;
            }

            .comfort-data {
                flex-grow: 1;
            }

            h1 {
                font-size: inherit;
                font-weight: 700;
            }

            .units {
                display: flex;
                flex-flow: column nowrap;
            }

            .units > div {
                display: flex;
                flex-wrap: wrap;
                gap: 6px;
            }

            .units table {
                flex-grow: 1;
            }

            .optimisation-table {
                align-self: flex-start;
            }

            .regular-weight {
                font-weight: 400;
            }

            .settings-tables table {
                flex-grow: 1;
            }

            table {
                border-collapse: separate;
                border-spacing: 0 6px;
            }

            td, th {
                text-align: left;
                padding: 10px;
                background-color: ${grey(0.03)};
            }

            td {
                font-weight: 700;
            }

            th {
                font-weight: normal;
            }

            tr :first-child {
                border-radius: 8px 0 0 8px;
            }

            tr :last-child {
                border-radius: 0 8px 8px 0;
            }

            tr :first-child:last-child {
                border-radius: 8px 8px 8px 8px;
            }

            /* Phones and narrow tablets in portrait */
            @media only screen
            and (max-width: 736px) /* Should we change MAX_PHONE_LAYOUT_WIDTH to be 736px? GHB */
            and (orientation: portrait) {
                /* deconstruct and then reassemble settings table */
                :host {
                    justify-content: start;
                }

                .units {
                    flex-grow: 1;
                }

                .settings-tables {
                    display: table;
                    border-collapse: separate;
                    border-spacing: 0 6px;
                }

                .settings-tables table {
                    display: contents;
                }

                .comfort-settings {
                    flex-direction: column;
                }

                .optimisation-table {
                    display: none;
                }

                .mobile-optimisation-row {
                    display: table-row;
                }

                .comfort-table td {
                    flex-direction: column;
                }
            }

            /* Inverse media query — all other cases */
            @media not screen
            and (max-width: 736px)
            and (orientation: portrait) {
                /* nothing */
            }
        `;
    }
}

export class PWRoutingWindUnits extends GenericRoutingUnits {
    render() {
        return html`
          <div class=units>
            <h1>Units</h1>
            <table>
              <tr>
                <th>Speed
                <td>${getUnitLabel(preference_units.wind_speed)}
              <tr>
                <th>Direction
                <td>${getUnitLabel(preference_units.wind_direction)}
            </table>
          </div>
          ${this.renderPolarSettings()}
        `;
    }
}

export class PWRoutingCurrentUnits extends GenericRoutingUnits {
    render() {
        return html`
          <div class=units>
            <h1>Units</h1>
            <table>
              <tr>
                <th>Speed
                <td>${getUnitLabel(preference_units.current_speed)}
              <tr>
                <th>Direction
                <td>${getUnitLabel(preference_units.current_direction)}
            </table>
          </div>`;
    }
}

export class PWRoutingWindNotes extends LitElement {
    render() {
        return html`
          <div>
            <h1>Note
              <table>
                <tr>
                  <td>${text_note_TWA_displayed_as_VMG}
              </table>
          </div>
        `;
    }

    static get styles() {
        return GenericRoutingUnits.styles;
    }
}

export class PWRoutingSummaryUnits extends GenericRoutingUnits {
    render() {
        return html`
          <div class=units>
            <h1>Units</h1>
            <div>
              <table>
                <tr>
                  <th>Speed
                  <td>${getUnitLabel(preference_units.wind_speed)}
                <tr>
                  <th>Direction
                  <td>${getUnitLabel(preference_units.wind_direction)}
                <tr>
                  <th>Wave Height</th>
                  <td>${getUnitLabel(preference_units.wave_height)}</td>
                </tr>
                <tr>
                  <th>Period</th>
                  <td>${getUnitLabel(preference_units.wave_period)}</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th>Pressure</th>
                  <td>${getUnitSymbol(preference_units.pressure)}</td>
                </tr>
                <tr>
                  <th>Temperature</th>
                  <td>${getUnitLabel(preference_units.temperature)}</td>
                </tr>
                <tr>
                  <th>Rainfall</th>
                  <td>${getUnitSymbol(preference_units.rainfall)}</td>
                </tr>
                <tr>
                  <th>CAPE</th>
                  <td>${getUnitSymbol(preference_units.cape)}</td>
                </tr>
              </table>
            </div>
          </div>
          ${this.renderPolarSettings()}
        `;
    }

    static get styles() {
        // Override to apply single table for routing settings unconditionally (instead of just for small screens)
        return [super.styles, // language=css
            css`
                .settings-tables {
                    display: table;
                    border-collapse: separate;
                    border-spacing: 0 6px;
                }

                .settings-tables table {
                    display: contents;
                }

                .polar-settings {
                    display: flex;
                    flex-flow: column;
                }
            `];
    }
}

export class PWRoutingSummaryComfortSettings extends GenericRoutingUnits {
    render() {
        return this.renderComfortSettings();
    }
}

export class PWRoutingAtmosphereUnits extends GenericRoutingUnits {
    render() {
        return html`
          <div class=units>
            <h1>Units</h1>
            <div>
              <table>
                <tr>
                  <th>Pressure</th>
                  <td>${getUnitSymbol(preference_units.pressure)}</td>
                </tr>
                <tr>
                  <th>Temperature</th>
                  <td>${getUnitLabel(preference_units.temperature)}</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th>Rainfall</th>
                  <td>${getUnitSymbol(preference_units.rainfall)}</td>
                </tr>
                <tr>
                  <th>CAPE</th>
                  <td>${getUnitSymbol(preference_units.cape)}</td>
                </tr>
              </table>
            </div>
          </div>
          ${this.renderPolarSettings()}
        `;
    }

    static get styles() {
        // noinspection JSValidateTypes
        return [super.styles, // language=css
            css`
                .units {
                    flex-grow: 1;
                }
            `];
    }
}

export class PWRoutingDeparturePlanningTables extends GenericRoutingTables {
    static get properties() {
        // Add a property "isAggregate" reflecting the "Summary" vs "Details" mode
        // The word "summary" already has a meaning in this file, so I'm trying not to overload it.
        // The word "aggregate" seems appropriate. To express the results for one departure date
        // as a single column, we aggregate the statistics across all sources.
        return {
            ...super.properties,
            isAggregate: {type: Boolean}
        };
    }

    constructor() {
        super();
        this.isAggregate = false;
    }

    update(changedProperties) {
        if (this.isAggregate && (changedProperties.has('isAggregate') || changedProperties.has('stats'))) {
            this.aggregateStats = summarisedDepartureStats(this.stats);
        }
        this.dataset.selectedDepartureIndex ??= 0;
        super.update(changedProperties);
    }

    render() {
        return this.renderSummaryTable(true);
    }

    static get styles() {
        return [
            super.styles,
            // language=css
            css`
                :host {
                    align-items: stretch;
                }
            `
        ];
    }
}
